import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Button } from '@material-ui/core';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../components/footer';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: 1200,
    padding: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(2),
    width: 250,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    padding: theme.spacing(2),
    borderRadius: '12px',
    boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.2)',
  },
  logo: {
    height: '50px',
    width: 'auto',
    margin: 'auto',
  },
  button: {
    margin: 'auto',
    marginTop: theme.spacing(4),
    display: 'block',
    width:250
  },
}));

const CryptoCard = ({ coinname, logo, symbol, description }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.card} elevation={3}>
      <img className={classes.logo} src={logo} alt={`${coinname} logo`} />
      <div>
        <h2>{coinname}</h2>
        <p>{description}</p>
        <h4>{symbol}</h4>
      </div>
    </Paper>
  );
};

const CryptoCardList = () => {
  const classes = useStyles();
  const services = [
    {
      image: 'https://i.postimg.cc/KYhRPp4H/image.png',
      description: 'Your funds will not be locked and you can still use them'  },
    {
      image: 'https://i.postimg.cc/t4k46RQY/image.png',
      description: 'Grandstaking gives you maximum flexibility and provides you with rewards every day'  },
    {
      image: 'https://i.postimg.cc/4xmS6wjV/image.png',
      description: 'We think about cryptocurrency newbies and want to give them easy access to start earning' },
    {
      image: 'https://i.postimg.cc/cCvn9gW9/image.png',
      description: ' leader in featured assets with over 20 top earning cryptocurrencies available'
    },
    {
      image: 'https://i.postimg.cc/tRBwmmSM/image.png',
      description: 'Low fees'
    }
  ];
const cryptoData=[
    
    {
      "coinname": "Ethereum",
      "logo": "https://cryptologos.cc/logos/ethereum-eth-logo.png",
      "symbol": "ETH",
      "description": "A decentralized platform for building smart contracts and decentralized applications (dApps)."
    },
    {
      "coinname": "Cardano",
      "logo": "https://cryptologos.cc/logos/cardano-ada-logo.png",
      "symbol": "ADA",
      "description": "A proof-of-stake blockchain platform designed to enable secure and reliable transactions."
    },
    {
      "coinname": "Binance Coin",
      "logo": "https://cryptologos.cc/logos/binance-coin-bnb-logo.png",
      "symbol": "BNB",
      "description": "The native token of the Binance exchange, used for trading fees, discounts, and other features."
    },
    {
      "coinname": "Polkadot",
      "logo": "https://cryptologos.cc/logos/polkadot-new-dot-logo.png",
      "symbol": "DOT",
      "description": "A multichain network that enables cross-chain interoperability and communication between different blockchains."
    },
    {
      "coinname": "Chainlink",
      "logo": "https://cryptologos.cc/logos/chainlink-link-logo.png",
      "symbol": "LINK",
      "description": "A decentralized oracle network that provides real-world data to smart contracts on the blockchain."
    },
    {
      "coinname": "Stellar",
      "logo": "https://cryptologos.cc/logos/stellar-xlm-logo.png",
      "symbol": "XLM",
      "description": "A decentralized payment network that facilitates cross-border transactions and asset transfers."
    },
    {
      "coinname": "VeChain",
      "logo": "https://cryptologos.cc/logos/vechain-vet-logo.png",
      "symbol": "VET",
      "description": "A blockchain platform that focuses on supply chain management and asset tracking."
    },
    {
      "coinname": "Theta",
      "logo": "https://cryptologos.cc/logos/theta-token-theta-logo.png",
      "symbol": "THETA",
      "description": "A decentralized video delivery network that aims to improve the video streaming experience for users."
    },
    {
      "coinname": "Solana",
      "logo": "https://cryptologos.cc/logos/solana-sol-logo.png",
      "symbol": "SOL",
      "description": "A high-performance blockchain platform that enables fast and cheap transactions with low fees."
    },
    {
      "coinname": "Avalanche",
      "logo": "https://cryptologos.cc/logos/avalanche-avax-logo.png",
      "symbol": "AVAX",
      "description": "A blockchain platform that enables decentralized applications and enterprise blockchain solutions."
    },
    {
      "coinname": "Tezos",
      "logo": "https://cryptologos.cc/logos/tezos-xtz-logo.png",
      "symbol": "XTZ",
      "description":"An open-source blockchain platform that allows for self-amendment and on-chain governance through a proof-of-stake consensus mechanism."
  },
  {
  "coinname": "Cosmos",
  "logo": "https://cryptologos.cc/logos/cosmos-atom-logo.png",
  "symbol": "ATOM",
  "description": "A decentralized network of independent blockchains that can communicate with each other through the Cosmos Hub."
  },
  {
  "coinname": "Algorand",
  "logo": "https://cryptologos.cc/logos/algorand-algo-logo.png",
  "symbol": "ALGO",
  "description": "A blockchain platform that aims to provide secure and efficient transactions with fast confirmation times."
  },
  {
  "coinname": "NEO",
  "logo": "https://cryptologos.cc/logos/neo-neo-logo.png",
  "symbol": "NEO",
  "description": "A blockchain platform that enables the development of decentralized applications and smart contracts."
  },
  {
  "coinname": "Harmony",
  "logo": "https://cryptologos.cc/logos/harmony-one-logo.png",
  "symbol": "ONE",
  "description": "A fast and secure blockchain platform that aims to enable the creation of decentralized applications and marketplaces."
  },
  {
  "coinname": "Zilliqa",
  "logo": "https://cryptologos.cc/logos/zilliqa-zil-logo.png",
  "symbol": "ZIL",
  "description": "A blockchain platform that uses sharding to enable high throughput and low transaction fees for decentralized applications."
  },
  {
  "coinname": "ICON",
  "logo": "https://cryptologos.cc/logos/icon-icx-logo.png",
  "symbol": "ICX",
  "description": "A blockchain platform that aims to connect various blockchain networks and enable interoperability between them."
  },
  {
  "coinname": "Ontology",
  "logo": "https://cryptologos.cc/logos/ontology-ont-logo.png",
  "symbol": "ONT",
  "description": "A blockchain platform that focuses on identity verification and data exchange through smart contracts."
  },
  {
  "coinname": "Matic Network",
  "logo": "https://cryptologos.cc/logos/matic-network-matic-logo.png",
  "symbol": "MATIC",
  "description": "A layer 2 scaling solution that aims to improve the performance and scalability of Ethereum-based decentralized applications."
  },
  {
  "coinname": "Elrond",
  "logo": "https://cryptologos.cc/logos/elrond-egld-logo.png",
  "symbol": "EGLD",
  "description": "A blockchain platform that uses sharding and adaptive state sharding to enable fast and secure transactions with low fees."
  },
  {
  "coinname": "Kusama",
  "logo": "https://cryptologos.cc/logos/kusama-ksm-logo.png",
  "symbol": "KSM",
  "description": "A blockchain platform that enables innovation and experimentation by allowing developers to build and deploy custom blockchain solutions."
  },
  {
  "coinname": "Polygon",
  "logo": "https://cryptologos.cc/logos/polygon-matic-logo.png",
  "symbol": "MATIC",
  "description": "A layer 2 scaling solution for Ethereum that aims to provide faster and cheaper transactions for decentralized applications."
  }
  ]
  return (
    <>
    <header className="header_section" style={{backgroundColor:'#0b1a9e'}}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg custom_nav-container ">
              <a className="navbar-brand" href="/">
                <span>
           <img src={require('../logo.png')} alt="" height="60"/>
                </span>
              </a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className="d-flex  flex-column flex-lg-row align-items-center">
                  <ul className="navbar-nav  ">
                    <li className="nav-item active">
                      <a className="nav-link" href="/" onClick={(e)=>{ e.preventDefault();window.location.href="/" }} style={{cursor:'pointer'}}>Home <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/Company" onClick={(e)=>{ e.preventDefault() ;window.location.href="/company"}} style={{cursor:'pointer'}}>About Us </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/staking" onClick={(e)=>{ e.preventDefault() ;}} style={{cursor:'pointer'}}>Assets </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/support" onClick={(e)=>{ e.preventDefault() ;window.location.href="/support"}} style={{cursor:'pointer'}}>Support&Help </a>
                    </li>
                   
                  </ul>
                 
                </div>
              </div>
              <div style={{backgroundColor:'tomato',margin:'auto',width:'200px',color:"white",padding:'3px',marginRight:'auto',zIndex:"0"
                  }}> <a className="nav-link" onClick={(e)=>{ window.location.href="/login"}} style={{cursor:'pointer'}}>SIGN IN</a></div>
          <div style={{backgroundColor:'teal',margin:'auto',width:'200px',color:"white",padding:'3px',marginRight:'auto',zIndex:"0"
                  }}> <a className="nav-link" onClick={(e)=>{ window.location.href="/register"}} style={{cursor:'pointer'}}>REGISTER</a></div>
   </nav>
          </div>
        </div>
      </div>
    </header>
    <div height={735} className="sc-iBYQkv fStxHs">
  <div height={735} className="sc-ipEyDJ bEpVGt" />
  <div className="sc-ftTHYK kvHrOR">
    <div color="var(--color--primary)" className="sc-pyfCe eASrxj">
      <h1 className="sc-gWoSmj dssSIF">
        Earn on <span>Crypto Assets</span>
      </h1>
    </div>
    <div className="sc-jrcTuL fqlHZJ">
      Check out our collection of +120 hand-picked coins, and take full
      advantage of earning on crypto
    </div>
   
  </div>
  <div className="sc-hJVpLM cEXGkA">
    <img
      alt=""
      src="https://www.mycointainer.com/landing-main/media/assets-list-header-image.346b821d.png"
    />
  </div>
</div>

    <div style={{textAlign:'Center',margin:'60px auto'}}> <h1>Available Assets for staking</h1>
    <p style={{margin:'10px auto',maxWidth:'80%'}}>We have collected the best offers from the world of DeFi for your earnings. Find
out more about the cryptocurrencies we offer and how profitable they are</p></div>
   
      <div className={classes.root}>
        {cryptoData.map((crypto) => (
          <CryptoCard key={crypto.symbol} {...crypto} />
        ))}
      </div>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        href="/register"
      >
        Start Earning
      </Button>
    
    <div className="container" style={{backgroundColor:"white",marginTop:'80px'}}>
      
      <div className="content-container" style={{backgroundColor:"white"}}>
        <p>
          
        </p>
        <div className="row">
          <div className="col-md-3 col-sm-6">
            
            <div className="detail-box" style={{backgroundColor:"white"}}>
              <h2 style={{color:'#0b1a9e'}}>
               20+
              </h2>
              <h6 style={{color:'#0b1a9e'}}>
              staking assets available for you
              </h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
           
            <div className="detail-box" style={{backgroundColor:"white"}}>
              <h2 style={{color:'#0b1a9e'}}>
               14%
              </h2>
              <h6 style={{color:'#0b1a9e'}}>
              of the average APY you can get
              </h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
           
            <div className="detail-box" style={{backgroundColor:"white"}}>
              <h2 style={{color:'#0b1a9e'}}>
                4500+
              </h2>
              <h6 style={{color:'#0b1a9e'}}>
                CLIENTS
              </h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
           
            <div className="detail-box" style={{backgroundColor:'white'}}>
              <h2  style={{color:'#0b1a9e'}}>
              7M USD+
              </h2>
              <h6 style={{color:'#0b1a9e'}}>
              in rewards given to our Users so far
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div style={{textAlign:'Center',margin:'120px auto'}}> <h1>Enjoy staking with GrandStaking.</h1>

   </div>
   <Container fluid>
      <Row style={{marginBottom:"40px"}}>
        {services.map((service, index) => (
          <Col md={4} sm={6} key={index}>
            <div className="service-card" style={{margin:'20px 50px'}}>
              <img src={service.image} alt={`Service ${index+1}`} />
              <p style={{margin:'20px 0px',fontWeight:'bold'}}>{service.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
    </div>
    <div className="sc-cwSeag emhsJJ">
  <img
    alt=""
    src="https://www.mycointainer.com/landing-main/media/footer-texture.d161594c.svg"
    className="sc-lllmON fgViNB"
    style={{ height: 593, width: "100%" }}
  />
  <div className="sc-iJnaPW gvcNWt">
    <div className="sc-gikAfH foHjIi">
      Get started today with as little as €1 and stake <span>over 100</span>{" "}
      cryptocurrencies
    </div>
    <div className="sc-ezOQGI deCcyC">Download our app on Android or iOS(Coming this December)</div>
    <div className="sc-bYMpWt jiFSkU">
      <a
        
      >
        <img alt="" src="https://www.mycointainer.com/landing-main/media/app-store.10182d30.svg" />
      </a>
      <a
       
      >
        <img alt="" src="https://www.mycointainer.com/landing-main/media/play-market.f5ed18aa.svg" />
      </a>
    
    </div>
  </div>
  <img
    loading="lazy"
    alt=""
    src="https://i.postimg.cc/LsW6qkjg/image-1.jpg"
    className="sc-kMjNwy ejGqCd"
  />
</div>

<Footer/>
    </>
  );
};

export default CryptoCardList;
