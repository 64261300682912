

import { appBarClasses } from "@mui/material";
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
const firebaseConfig = {
    apiKey: "AIzaSyCDntZI-SFqwjDlpIea7LKfST9Pxjl-1HY",
  authDomain: "grandstaking.firebaseapp.com",
  projectId: "grandstaking",
  storageBucket: "grandstaking.appspot.com",
  messagingSenderId: "627526715431",
  appId: "1:627526715431:web:5904ce4c99f409a068e602"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
export const db = getDatabase(app);
export const storage=getStorage(app)

export {auth}