import React, { useState, useEffect,useCallback } from "react";
import Wallets from "./Walletform";
import { auth } from '../FirebaseConfig';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useLocation } from 'react-router-dom';

function UserMenu() {
  const [users, setUsers] = useState([]);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
const[uid,setuid]=useState("")
const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user&& user.uid=="4jUyqtiYOsMuIP1hKBPJMGLPZxG2") {
        // User is signed in.
        setLoading(false);
        
          fetch("https://grandstaking-default-rtdb.firebaseio.com/users.json")
            .then((response) => response.json())
            .then((data) => {
              const usersArray = [];
              for (const key in data) {
                const user = {
                  id: key,
                  email: data[key].userData.email,
                };
                usersArray.push(user);
              }
              setUsers(usersArray);
            })
            .catch((error) => console.log(error));
        // You can load your page here
      } else {
        // User is signed out.
        navigate("/login")
        setLoading(false);
      }
    });

    return () => {
      unsubscribe(); // Unsubscribe from the auth state listener when component unmounts
    };
  }, [navigate]);



 
  const [showConfirmation, setShowConfirmation] = useState(false);
 

  
  const handleDelete = () => {
    setShowConfirmation(true);
  };

  const confirmDelete = () => {
    // do something when the user confirms the delete
    handleDeleteClick(uid)
    setShowConfirmation(false);

  };

  const cancelDelete = () => {
    // do something when the user cancels the delete
    setShowConfirmation(false);
  };
  function handleEditClick(userId) {
    window.open(`/adminuser?userId=${userId}`, "_blank");
  }

  function handleDeleteClick(userId) {
    fetch(`https://grandstaking-default-rtdb.firebaseio.com/users/${userId}.json`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then(() => {
        const updatedUsers = users.filter((user) => user.id !== userId);
        alert('successfull delete')
        setUsers(updatedUsers);
      })
      .catch((error) => console.log(error));
  }

  return (
    <div style={{maxWidth:'70%',margin:'10px auto'}}>
        <div>
      {showConfirmation && (
        <div>
          <p>Are you sure you want to delete?</p>
          <button onClick={confirmDelete}>Yes</button>
          <button onClick={cancelDelete}>No</button>
        </div>
      )}
     
    </div>
      <h2>Users Menu</h2>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
              <div style={{display:'flex',justifyContent:'space-between'}}> <p>{user.email}</p>
              <div>  <button onClick={() =>{handleEditClick(user.id)}}>Edit</button>
            <button onClick={() => {
                
                
                setuid(user.id)
                handleDelete()}}>Delete</button></div>
           </div>
          
          </li>
        ))}
      </ul>
      <h3>Wallets</h3>
     <Wallets/>
    </div>
  );
}

export default UserMenu;
