import React from 'react';
import Footer from '../components/footer';
import Navbar from '../components/Navbar';
import FAQSection from '../components/faq';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCheck, faCheckCircle,faCloudArrowUp,faGamepad,faGlobe, faHeadphones, faHeartPulse, faLeaf, faShoppingBasket, faTimeline } from '@fortawesome/free-solid-svg-icons';

const features = [
  
  {
    icon: faGamepad,
    title: 'Order Customization',
    description: 'Customise large hash rate orders to create a mining plan that meets your unique needs.',
  },
  {
    icon: faCloudArrowUp,
    title: 'All in one hosting',
    description: 'Buy your own miner and we will host it on your behalf (so you dont need to install and run mining hardware and software)',
  },
  {
    icon: faShoppingBasket,
    title: 'Procurement Compliance',
    description: 'Transparently adhere to company spend protocol with convenient data-tracking and support.',
  },
  {
    icon: faHeadphones,
    title: 'Exclusive Consultation',
    description: 'Receive exclusive consultations and support.',
  },
 
];
const Ins = () => {
  const years = ['2019', '2020', '2021', '2022', '2023'];
  const buttonStyle = {
    padding: '10px 15px',
    backgroundColor: '#01B580',
    color: 'white',
    border: '1px solid white',
    cursor: 'pointer',
    borderRadius:'2px'
  };
  const faqs= [
    {
      "question": "What is Elite Minng?",
      "answer": "Elite-Mining is a Cryptocurrency cloud mining service that offers an easy and safe way to purchase hashpower without having to deal with complex hardware and software setup. We offer hosted cryptocurrency mining services and a variety of mining related solutions to small and large scale customers. The combination of our algorithmic trading framework, mining infrastructure, and proprietary mining farm-management software, Elite Hive, quickly made us an industry leader. Our team of mining experts with extensive knowledge of the digital currency sector specializes in building the most efficient and reliable mining facilities. Elite Mining is also the founding partner of Logos Fund, the first ever Bitcoin Mining Fund, which targets professional investors looking to gain access to Bitcoin’s and various other digital assets’ potential. Our service was founded by the end of 2013 and with now over 2.000.000 users we are the world‘s leading multi-algorithm cloud mining service."
    },
    {
      "question": "How does Bitcoin mining work with us?",
      "answer": "It’s quick and very easy! As soon as we receive your payment your contract will be added to your profile, and you can immediately start mining. Depending on the blockchain algorithm you select and the associated mining service agreement you enter into, you can either mine native cryptocurrencies directly or allocate your hashpower to other cryptocurrencies (marked with AUTO), and even choose a specific allocation for them. For example: 60% LTC, 20% BTC and 20% DOGE. The first mining output is released after 48 hours, and then a daily mining output will follow. Remark: Every day of mining will be processed and sent to your account in the following 24 hours after the mining day is over. Please refer to section “When do I get my mining output?” for more details."
    },
    {
      "question": "What s the mining Performance? Why not mine your self",
      "answer": "Besides the fact that we ourselves mine with the very same hardware that we offer to our clients, our capital is limited. We believe that Bitcoin and Altcoin mining is one of the best ways to receive Cryptocurrencies, however, we do not want to “put all our eggs in one basket”."
    },
    {
      "question": "What coins can i mine with what algorithm?",
      "answer": "You are able to mine Bitcoin and various altcoins directly via our mining allocation page*. The availability of cryptocurrencies you can mine depends on the contract you have chosen. You must allocate your hashpower in order to determine the cryptocurrency received for your mining output. If no allocation has been made, the mining output will default to the following for the given blockchain algorithm: SHA-256 contract - Bitcoin X11 contract - Dash Ethash contract - Ethereum CryptoNight contract - Monero/Monero Classic Scrypt - Litecoin Equihash - Zcash (*) “Elite Mining Advanced Allocation” (special feature): It allows you to get mining outputs in many different coins even if they are not mined directly by a certain algorithm. For example, you can get mining outputs in BTC while mining with an X11 algorithm! That is possible by mining the DASH coin directly, which is then automatically swapped to BTC by our algorithmic framework. The Allocation function is designed for customers to receive delivery of their mining results in their preferred cryptocurrency. We call it “mining BTC the smart way”. The same technique is also used to get mining results in LTC with a SHA-256 contract, etc."
    },
    {
      "question": "Whichpools are used for mining?",
      "answer": "We do not publish a list of pools we are using. Our main criteria for a good pool are: reliability, fee structure and reject rate. Going forward we will solo-mine a few coins (and pass the fee savings to our users!). Our internal policy is: “be a good crypto citizen”. This means, that we will at least use two different pools (in some cases we use up to four) for each coin. This is to preserve the decentralized nature of the crypto networks! If we become aware that a pool is getting close to 50% share, we will switch away from it and use a backup instead."
    }
  ]
  return (
      <> 
   <Navbar/>
   <section className="">
      <div className="planheader"></div>
      <div className="cont cont2">
        <h1 className="header">Institutional Investors Cryptocurrency Solutions</h1>
        <p className="text">Our multi-mode, multi-strategy mining plans are now available for institutional and retail customers and various types of enterprises.</p>
        <button style={buttonStyle} onClick={()=>{window.location.href="/register"}}>Get started today</button>
      </div>
      </section>
  <br />
      <div>
          <h1 style={{maxWidth:"60%",textAlign:'center',margin:"auto"}}> An all-in-one platform with personalised support
 </h1>
    <div className="feature-grid">
        
      {features.map((feature, index) => (
        <div className="feature-card" key={index}>
          <div className="icon-container">
            <FontAwesomeIcon icon={feature.icon} className="icon2" />
          </div>
          <div className="text-container">
            <h2 className="title">{feature.title}</h2>
            <p className="description2">{feature.description}</p>
          </div>
        </div>
      ))}
    </div>
    </div>
    <section className="responsive-section">
   
      <div className="image-container">
        <img src="https://i.postimg.cc/qv9Xvgdj/image.png" width={300}alt="Responsive Image" className="responsive-image" />
      </div>
      <div className="text-container">
        <div className="text-content">
          <div> <strong><h1>Track your data and revenue</h1></strong>
          <br /></div>
           <div className="feature-card2" >
          <div className="icon-container">
            <FontAwesomeIcon icon={faCheck} beat className="icon2" />
          </div>
          <div className="text-container">
            <h2 className="title">Multi Node & Multi Strategy</h2>
            <p className="description2">Effortlessly tailor your hosting size, pick your preferred location, and choose the hosting mode that meets your needs.

</p>
          </div>
        </div>
        <div className="feature-card2" >
          <div className="icon-container">
            <FontAwesomeIcon icon={faCheck} beat className="icon2" />
          </div>
          <div className="text-container">
            <h2 className="title">Realtime data monitoring</h2>
            <p className="description2">Monitor your mining revenue, notifications and other valuable data with ease.

</p>
          </div>
        </div>
        <div className="feature-card2" >
          <div className="icon-container">
            <FontAwesomeIcon icon={faCheck} beat className="icon2" />
          </div>
          <div className="text-container">
            <h2 className="title">Track your revenue</h2>
            <p className="description2">Keep track of the hash rate and income of all your mining pools with real-time and historical data.



</p>
          </div>
        </div>
        <br />
         </div>
      </div>
     
    </section>
  <Footer/>
    </>
  );
};

export default Ins;