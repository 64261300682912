import React, { useEffect, useState } from 'react';

const ActivePlans = ({ userId }) => {
  const [activePlans, setActivePlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
 
  useEffect(() => {
    if (!userId) {
      setIsLoading(false);
      return;
    }

    // Firebase Realtime Database REST API endpoint
    const apiUrl = `https://grandstaking-default-rtdb.firebaseio.com/users/${userId}/userData/active-plans.json`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // Convert the data object to an array of plans
          const plansArray = Object.entries(data).map(([id, plan]) => ({ id, ...plan }));

          // Filter the plans to find active ones
          const activePlans = plansArray.filter((plan) => plan.status === 'active');

          setActivePlans(activePlans);
        } else {
          // No active plans found
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, [userId]);
  return (

  <div className="active-plans-container">
  {isLoading ? (
    <p>Loading...</p>
  ) : activePlans.length === 0 ? (
    <p>No active plans yet. Go to /plan to start.</p>
  ) : (
    <div>
      <h2>Active Mining</h2>
     <div className='activecontainer'>
        {activePlans.map((plan) => (
            <ul className='activebox'>
          <li key={plan.id}><h2>{plan.id}</h2></li>
          <li ><font color="teal"><h4><strong>{plan.hashrate}</strong></h4></font></li>
          <li><small>TH/s</small></li>
          <li >{plan.status}</li>
          </ul>
        ))}
      </div>
    </div>
  )}
</div>
);
};

export default ActivePlans;