import React, { useEffect } from 'react';
import { toast } from 'react-toastify';


const usernames = [
    "JohnSmith123",
    "SarahJohnson89",
    "MichaelBrown75",
    "JennyDavis",
    "David_Wilson",
    "Linda_Anderson",
    "JamesC23",
    "Patty.Jones",
    "RobMiller_42",
    "Mary_Martinez99",
    "WillDavis007",
    "Liz_Moore21",
    "Charlie_J",
    "Maggie_Taylor",
    "Thomas.Lee12",
    "Karen.H",
    "Rich_White",
    "Sue_Wilson",
    "JoeWright88",
    "DorothyG",
    "DanKing34",
    "Lisa_Young22",
    "Paul.Walker",
    "BettyT_123",
    "MarkH23",
    "Nancy.G",
    "George_Scott",
    "HelenGreen3",
    "Ken.Adams",
    "Sandra_Carter88",
    "Steve_Evans_7",
    "Donna_A",
    "Ed_Perez",
    "CarolHall54",
    "BrianT_67",
    "RuthFloresX",
    "KevinHoward99",
    "Miche_Wood",
    "RonnyB_42",
    "Kim_Cook",
    "Jason.Ross",
    "MandyStewart",
    "Gary.M_10",
    "Laura_Rivera",
    "Jeff.Mitchell",
    "Sharon.Y_78",
    "Ryan_C",
    "Cynthia.R_22",
    "TimSmith777",
    "Becca.D",
  ];
  

const actions = ['deposited', 'withdrew', 'earned'];

function getRandomItemFromArray(arr) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

function getRandomDollarAmount() {
  const minAmount = 500;
  const maxAmount = 15000;
  const randomAmount = (Math.random() * (maxAmount - minAmount) + minAmount).toFixed(2);
  return `$${randomAmount}`;
}

function ToastComponent() {
  useEffect(() => {
    const generateToast = () => {
      const username = getRandomItemFromArray(usernames);
      const action = getRandomItemFromArray(actions);
      const amount = getRandomDollarAmount();
      const message = `${username}`+' just '+` ${action} ${amount}`;

      toast.info(message); // Use toast to display the message

      // Generate a random interval between 5 and 15 seconds (adjust as needed)
      const randomInterval = Math.floor(Math.random() * 200000) + 30000;

      // Set a timeout to generate the next toast
      setTimeout(generateToast, randomInterval);
    };

    generateToast(); // Initial toast

  }, []);

  return null; // This component doesn't render any visible elements
}

export default ToastComponent;
