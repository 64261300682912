import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const FAQSection = ({ faqs }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box m={2}   
    display="flex"
    flexDirection="column"
    alignItems="center"
    bgcolor="rgb(248, 248, 248)"
    borderRadius="16px"
    boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
    p={4}>
      <Typography variant="h4" gutterBottom>
        Frequently Asked Questions
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
            style={{ width: "100%", maxWidth: 600, marginBottom: 16 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default FAQSection;
