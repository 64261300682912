import React,{ useState } from 'react';
 // Create a CSS file for styling
import image1 from './image1.jpg'
import image2 from './image2.jpg'
import image3 from './image3.jpg'
import image4 from './image4.jpg'
import image5 from './image5.jpg'
function CenteredSection() {
  const [bgImage, setBgImage] = useState(image1); // Initial background image

  const changeBackground = (newImage) => {
    setBgImage(newImage);
  };
  const buttonStyle = {
    padding: '5px 12px',
    backgroundColor: '#01B580',
    color: 'white',
    border: '1px solid white',
    cursor: 'pointer',
    borderRadius:'10px',
    width:'150px'
  };
  const buttonStyle2 = {
    padding: '5px 12px',
    backgroundColor: '#01B580',
    color: 'white',
    border: '1px solid white',
    cursor: 'pointer',
    borderRadius:'10px',
    fontWeight:'bold'
  };
  return (
    <section className="centered-section">
      <div className="left-component">
        <h1>DataCenter</h1>
        <p>Mining DataCenter is one of Elitemining's business verticals. Historically the team can be traced back to 2013 when it began providing specialized services for facility design, construction, maintenance, and general one-stop technical support. Currently we have 5 fully compliant datacenters around the world.</p>
        <br/>
        <br/>
        <button  style={buttonStyle}> Learn more</button>
      </div>
      <div className="right-component">
        <div className="image-container" style={{ backgroundImage: `url(${bgImage})` }}>
          <div className="button-container">
            <button  style={buttonStyle2}onClick={() => changeBackground(image1)}>Tennesse</button>
            <button  style={buttonStyle2} onClick={() => changeBackground(image2)}>Washington</button>
            <button style={buttonStyle2} onClick={() => changeBackground(image3)}>Texas</button>
            <button  style={buttonStyle2}onClick={() => changeBackground(image4)}>Molde</button>
            <button  style={buttonStyle2}onClick={() => changeBackground(image5)}>Tydal</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CenteredSection;
