import React from 'react';
import Footer from '../components/footer';
import Navbar from '../components/Navbar';
import FAQSection from '../components/faq';
import Pdf from '../components/em.pdf'
import Doc from '../components/doc.docx'
const Timeline = () => {
  const years = ['2019', '2020', '2021', '2022', '2023'];
  const buttonStyle = {
    padding: '10px 15px',
    backgroundColor: '#01B580',
    color: 'white',
    border: '1px solid white',
    cursor: 'pointer',
    borderRadius:'2px'
  };
  const faqs= [
    {
      "question": "What is Elite Minng?",
      "answer": "Elite-Mining is a Cryptocurrency cloud mining service that offers an easy and safe way to purchase hashpower without having to deal with complex hardware and software setup. We offer hosted cryptocurrency mining services and a variety of mining related solutions to small and large scale customers. The combination of our algorithmic trading framework, mining infrastructure, and proprietary mining farm-management software, Elite Hive, quickly made us an industry leader. Our team of mining experts with extensive knowledge of the digital currency sector specializes in building the most efficient and reliable mining facilities. Elite Mining is also the founding partner of Logos Fund, the first ever Bitcoin Mining Fund, which targets professional investors looking to gain access to Bitcoin’s and various other digital assets’ potential. Our service was founded by the end of 2013 and with now over 2.000.000 users we are the world‘s leading multi-algorithm cloud mining service."
    },
    {
      "question": "How does Bitcoin mining work with us?",
      "answer": "It’s quick and very easy! As soon as we receive your payment your contract will be added to your profile, and you can immediately start mining. Depending on the blockchain algorithm you select and the associated mining service agreement you enter into, you can either mine native cryptocurrencies directly or allocate your hashpower to other cryptocurrencies (marked with AUTO), and even choose a specific allocation for them. For example: 60% LTC, 20% BTC and 20% DOGE. The first mining output is released after 48 hours, and then a daily mining output will follow. Remark: Every day of mining will be processed and sent to your account in the following 24 hours after the mining day is over. Please refer to section “When do I get my mining output?” for more details."
    },
    {
      "question": "What s the mining Performance? Why not mine your self",
      "answer": "Besides the fact that we ourselves mine with the very same hardware that we offer to our clients, our capital is limited. We believe that Bitcoin and Altcoin mining is one of the best ways to receive Cryptocurrencies, however, we do not want to “put all our eggs in one basket”."
    },
    {
      "question": "What coins can i mine with what algorithm?",
      "answer": "You are able to mine Bitcoin and various altcoins directly via our mining allocation page*. The availability of cryptocurrencies you can mine depends on the contract you have chosen. You must allocate your hashpower in order to determine the cryptocurrency received for your mining output. If no allocation has been made, the mining output will default to the following for the given blockchain algorithm: SHA-256 contract - Bitcoin X11 contract - Dash Ethash contract - Ethereum CryptoNight contract - Monero/Monero Classic Scrypt - Litecoin Equihash - Zcash (*) “Elite Mining Advanced Allocation” (special feature): It allows you to get mining outputs in many different coins even if they are not mined directly by a certain algorithm. For example, you can get mining outputs in BTC while mining with an X11 algorithm! That is possible by mining the DASH coin directly, which is then automatically swapped to BTC by our algorithmic framework. The Allocation function is designed for customers to receive delivery of their mining results in their preferred cryptocurrency. We call it “mining BTC the smart way”. The same technique is also used to get mining results in LTC with a SHA-256 contract, etc."
    },
    {
      "question": "Whichpools are used for mining?",
      "answer": "We do not publish a list of pools we are using. Our main criteria for a good pool are: reliability, fee structure and reject rate. Going forward we will solo-mine a few coins (and pass the fee savings to our users!). Our internal policy is: “be a good crypto citizen”. This means, that we will at least use two different pools (in some cases we use up to four) for each coin. This is to preserve the decentralized nature of the crypto networks! If we become aware that a pool is getting close to 50% share, we will switch away from it and use a backup instead."
    }
  ]
  return (
      <> 
   <Navbar/>
   <section className="">
      <div className="planheader"></div>
      <div className="cont cont2">
        <h1 className="header">About us - Elite mining (NASDAQ: BTDR)</h1>
        <p className="text">We are a Nasdaq-listed company dedicated to providing reliable and comprehensive crypto mining solutions to global users. As of July 31, 2023, we have 6 mining data centers around the world, with a total hash rate of 18.8 EH/s.</p>
        <button style={buttonStyle} onClick={()=>{window.location.href="/register"}}>Get started today</button>
      </div>
      </section>
    <div className="sc-kRvVA iiIHgQ">
  <section className="sc-cNYriL dmZkMh">
    <h1 className="sc-gLgtkK kJRXsO">We've been providing digital mining solutions since 2018</h1>
    <p className="sc-gwGGKT hnpBDV">
      Our goal is to enable easy access to various rewarding crypto mechanisms
      for all. We want everyone to equally
      <br /> benefit from a changing financial landscape and speed up the global
      advent of crypto adoption.
    </p>
    <div role="img">
      <img alt="" width={614} src="https://www.mycointainer.com/landing-main/media/hero.1d3cc56e.png" />
    </div>
  </section>
</div>
    <center style={{margin:'10%'}}><h1>ABOUT ELITE MINING</h1>
    <p style={{margin:"10px 0",color:"rgb(105, 115, 134)",maxWidth:"70%"}}>We are a self-funded startup that has already grown into a profitable and scalable business in just over 2 years.
Get familiar with our history and highlights as well as upcoming plans.</p>
<p style={{margin:"10px 0",color:"rgb(105, 115, 134)",maxWidth:"70%"}}>The story of Elite Mining started at the end of 2013. Our founders got to know each other by using the same platform for buying and selling Bitcoins. They were fascinated by the technology and wanted to build their own farm, only to realize all their friends wanted to participate as well.</p>
<br/>
<p style={{margin:"10px 0",color:"rgb(105, 115, 134)",maxWidth:"70%"}}>They came up with the idea of mining as a service and built the first mining farm in Eastern Europe. Since our founding, we have grown tremendously and a lot has happened, but one thing remains constant: We are all strong believers in the future of digital currencies and we love being part of this growing community.</p>
</center>


<br/>
    <div className="timeline">
      
        <div key={years[0]} className="timeline-item">
          <div className="timeline-year">2022</div>
          <div className="timeline-cards">
            <div className="timeline-card">
              <div className="timeline-card-title">To get Series A Funding.
</div>
              <div className="timeline-card-body">Elite mining has been a self-funded business since 2018. We postponed our funding to prove that our project is strong enough and presents a good investment opportunity.</div>
            </div>
            <div className="timeline-card">
              <div className="timeline-card-title">To launch our own coin.</div>
              <div className="timeline-card-body">We want to create our own coin to support the Elite mining ecosystem and beyond with decentralized solutions.</div>
            </div>
            <div className="timeline-card">
              <div className="timeline-card-title">To increase the number of supported earning assets to 200.</div>
              <div className="timeline-card-body">UWe want to outpace and remain the go-to place for earning in crypto.</div>
            </div>
          </div>
        </div>
        <div key={years[1]} className="timeline-item">
          <div className="timeline-year">2021</div>
          <div className="timeline-cards">
            <div className="timeline-card">
              <div className="timeline-card-title">Collaboration with coin developers gets stronger.
</div>
              <div className="timeline-card-body">Partnerhips with Cartesi, Matic Polygon, Harmony, Nuls & Avalanche announced.</div>
            </div>
            <div className="timeline-card">
              <div className="timeline-card-title">The launch of four B2B tools.</div>
              <div className="timeline-card-body">Working towards bringing on board more businesses to the Elite mining ecosystem and produce mutual value for crypto-related businesess.</div>
            </div>
            <div className="timeline-card">
              <div className="timeline-card-title">We work towards a 150K user base.</div>
              <div className="timeline-card-body">As of June 2021, Elite mining has ~85K active users.</div>
            </div>
          </div>
        </div>
        <div key={years[2]} className="timeline-item">
          <div className="timeline-year">2020</div>
          <div className="timeline-cards">
            <div className="timeline-card">
              <div className="timeline-card-title">Successful renewal of Financial License.
</div>
              <div className="timeline-card-body">Over 2 years have proven a compliant Elite mining operations.</div>
            </div>
            <div className="timeline-card">
              <div className="timeline-card-title">Collaboration with many coin developers.</div>
              <div className="timeline-card-body">Helping to secure blockchains and running marketing campaigns for NEM, Crypto.com, and Validity.</div>
            </div>
            <div className="timeline-card">
              <div className="timeline-card-title">The launch of POWER subscription.</div>
              <div className="timeline-card-body">To enable various benefits for our audience.</div>
            </div>
          </div>
        </div>

    </div>
    <section className="sc-cNYriL dmZkMh">
    <h1 className="sc-gLgtkK kJRXsO">OUR MISSION</h1>
    <h2 className="sc-gLgtkK kJRXsO">Making mining accessible to everyone</h2>
    <p className="sc-gwGGKT hnpBDV">
    Elite Mining is one of the leading hashpower providers in the world, offering cryptocurrency mining capacities in every range - for newcomers, interested home miners, as well as large scale investors. Our mission is to make acquiring cryptocurrencies easy and fast for everyone.  </p>
    <p className="sc-gwGGKT hnpBDV">
    We provide a multi-algorithm, multi-coin cloud mining service using the latest technology - without any pool fees. The ultimate goal of our existence is to make cryptocurrency mining an easy, smart and rewarding experience for all. Our services already attracted more than 2.000.000 people - We’d be happy to serve you as well! </p>
    <p className="sc-gwGGKT hnpBDV">
    </p>
    <div role="img">
      <img alt="" width={614} src=" https://www.mycointainer.com/landing-main/media/header-image.fae3394c.webp" />
    </div>
  </section>
  <div className="responsive-two-columns-container">
      <div className="responsive-column">
        {/* Content for the first column */}
        <h1>We have strategic partnerships with top miner manufacturers</h1>
       
      </div>
      <div className="responsive-column">
        {/* Content for the second column */}
      
        <p>As a miner sharing platform, Bitdeer has strategic partnerships with top miner manufacturers, as well as renowned mining pools like BTC.com, ViaBTC, Foundry, AntPool, F2Pool and BTC.top. With us, you'll benefit from features like:</p>
     <ul>
       <li>Traceable miners</li>
       <li>Direct payouts from pools and flexible plans</li>
       <li>Authenticity and transparency</li>
       <li>Security</li>
       <li>Convenience</li>
       <li>24/7 operation</li>
     </ul>
     <p>With members across more than 200 countries and regions, over 3,000,000 websites visit each month, and mining farms deployed across Europe and North America, you'll be joining a global membership-base with a first-in-class miner sharing service.</p>
      </div>
    </div>
    <br/>
    <div className="sc-eDvSVe clNSFG">
    <div className="sc-GhhNo kSAeNE">
      <img alt="" width={348} src="https://i.postimg.cc/hjKBn0HF/epic.pnggit" />
      <div className="sc-fXqpFg cmHrlO">
        <div className="sc-hLBbgP eCVcVA">
          <div className="sc-UpCWa fcVBwG">
            Mine crypto and start your journey to financial freedom
          </div>
        </div>
        <div className="sc-jSUZER fbavso">
          It’s easy to earn with us. Let’s begin in three simple steps
        </div>
        <div className="sc-laZRCg etXeQW">
          <div className="sc-gGvHcT gsuCzl">
            <div role="term" className="sc-ckEbSK fKBnMn">
              1
            </div>
            <div role="heading" className="sc-fbYMXx eUFWGW">
              Create an account and verify your identity
            </div>
          </div>
          <div className="sc-gGvHcT gsuCzl">
            <div role="term" className="sc-ckEbSK fKBnMn">
              2
            </div>
            <div role="heading" className="sc-fbYMXx eUFWGW">
              Make a crypto deposit or buy assets with one of the multiple
              payment methods
            </div>
          </div>
          <div className="sc-gGvHcT gsuCzl ">
            <div role="term" className="sc-ckEbSK fKBnMn">
              3
            </div>
            <div role="heading" className="sc-fbYMXx eUFWGW">
              Keep coins in your wallet and watch your income grow
            </div>
          </div>
        </div>
        <a
          role="link"
          color="primary"
          href="/register"
          className="sc-gKPRtg sc-dwnOUR gXEEEU erQkjj"
        >
          Mine now
        </a>
      </div>
    </div>
  </div><br/>
  <br/>
  <br />
  <div className="container">
      <div className="column column1">
        <h1>Our mining farms</h1>
        <p>
        Disclaimer: The displayed datacenters represent only a fraction of the mining operations we own. Since security and customer protection are our highest priorities, not all data centers are shown. Nevertheless, we are able to take footage and set up live feeds at low-risk locations and we do so without disclosing sensitive information or critical intellectual property
        </p>
      </div>
      <div className="column column2">
        <img
          src="https://i.postimg.cc/SRTpNjv8/Mediamodifier-Design-Template.png"
          alt="Responsive Image"
          className="responsive-image"
        
        />
      </div>
     
    </div>
  <div>
    <center><h1>Our company documents</h1></center>
      </div>
      <div className="centered-container">
      <div className="flx">
        <div className="image-wrapper">
          <a href={Pdf} target="_blank">
            <img src="https://i.postimg.cc/CLvkZYY0/image.png" alt="" className="responsive-image" />
          </a>
        </div>
        <div className="image-wrapper">
          <a href={Doc} target="_blank">
            <img src="https://i.postimg.cc/26KWnyR2/image.png" alt="" className="responsive-image" />
          </a>
        </div>
      </div>
    </div>
  <section>
    <center style={{margin:'3%'}}> <h1>F.A.Q</h1></center>
    <div style={{ display: "flex", justifyContent: "center", marginTop: 32 }}> <FAQSection faqs={faqs} /></div>
 
 </section>
 <br />
 
  <Footer/>
    </>
  );
};

export default Timeline;