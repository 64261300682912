
import React,{useState} from 'react'
import Footer from '../components/footer';
import Modal from './modal';
import Navbar from '../components/Navbar';
function Privacy() {
    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = () => {
      setShowModal(true);
    };
  
    const handleModalClose = () => {
      setShowModal(false);
    };
  return (
    <div>
      
      <Navbar/>
  <div className="sc-lbzwWw tnQeT">
  <div>
    <h1>PRIVACY POLICY</h1>
    <p>
      Welcome to our Community! Elite mining OU, Ltd. ("company", "we","us",
      "our") is committed to protecting your personal data and right to privacy.
      We have written this Privacy Policy in the clearest way possible to let
      you know how we collect and use your data, what we use it for and whom we
      may share it with as well as other relevant policies designed for your
      protection. It applies to all information we collect through our website
      Elite-mining.com ("site"), IOS and Android app, Telegram Bot and Marketing
      Surveys.
    </p>
    <p>
      This document is important so please read it carefully. It will help you
      make informed decisions regarding the personal data you share with us and
      your privacy. Should you find anything in it that you do not like or agree
      with please discontinue use of our site and services. If you have any
      questions or need for clarification you may reach us at
      contactgdpr@Elite-mining.com.
    </p>
  </div>
  <div>
    <h4>TABLE OF CONTENTS</h4>
    <ol>
      <li>Are we a Data Controller or a Data processor?</li>
      <li>What data do we collect?</li>
      <li>How do we collect your data?</li>
      <li>How will we use your data?</li>
      <li>How do we store and secure your data?</li>
      <li>Will your information be shared?</li>
      <li>What are your privacy rights?</li>
      <li>What is our legal basis for collecting your data?</li>
      <li>Do we use cookies and other tracking technologies?</li>
      <li>What if you don't want cookies to be set?</li>
      <li>
        What is our policy regarding other websites with links in our website?
      </li>
      <li>How long do we keep your data?</li>
      <li>Do we transfer your data internationally?</li>
      <li>Do we collect data from minors?</li>
      <li>How do we communicate changes to our privacy policy?</li>
    </ol>
  </div>
  <div>
    <h4>ARE WE A DATA CONTROLLER OR DATA PROCESSOR?</h4>
    <p>
      We process personal information as a Processor as defined in the GDPR.
    </p>
  </div>
  <div>
    <h4>WHAT DATA DO WE COLLECT?</h4>
    <p>
      We may collect personal information of yours if you use services on our
      web site
      <a href="https://www.Elite-mining.com/">https://www.Elite-mining.com/</a>
      and for the purpose of provision of service(s) to you in accordance with
      our
      <a
        href="/agreement/terms-conditions/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Use
      </a>
      . Please note that if you refuse to share your Personal data for this
      purpose we will not be able to provide our services to you.
    </p>
    <p>The data we collect may include:</p>
    <ol>
      <li>your full name;</li>
      <li>your birth date</li>
      <li>your photographic identification;</li>
      <li>
        your identity documents details such as: document number, issue and
        expiration dates, etc.;
      </li>
      <li>your address;</li>
      <li>your phone number;</li>
      <li>your email address;</li>
      <li>
        your IP address, Browser and Operating System information, geolocation
        details;
      </li>
      <li>
        your banking details including account numbers and payment card data;
      </li>
      <li>your trades; and</li>
      <li>information regarding sources of your funds.</li>
      <li>information regarding source of wealth</li>
      <li>any other information that you can share with us</li>
    </ol>
  </div>
  <div>
    <h4>HOW DO WE COLLECT YOUR DATA?</h4>
    <p>
      We collect and process data when you: create the account, extend your
      account and participate in marketing surveys and contests.
    </p>
    <p>
      We automatically collect certain information when you visit, use or
      navigate our website. This information does not reveal your specific
      identity (like your name or information) but may include device and usage
      information such as your IP address, browser and device characteristics,
      operating system, language preference, referring URLs, device name,
      country, location, information about how and when you use our site and
      other technical information.
    </p>
    <p>
      Like many businesses, we also collect information through cookies and
      similar technologies. You can find out more about this in our Cookies
      Policy below.
    </p>
  </div>
  <div>
    <h4>HOW WILL WE USE YOUR DATA?</h4>
    <p>We collect and process your data for the following purposes:</p>
    <ol type="I">
      <li>create and manage your account</li>
      <li>provide you with the services we offer that you choose to avail</li>
      <li>authenticate your identity and device</li>
      <li>
        to comply with legal requirements such as the Anti-money laundering and
        other EU laws as well as laws outside the EU
      </li>
      <li>to improve site performance</li>
      <li>
        to communicate with you regarding matters that affect your account with
        us
      </li>
      <li>to offer you products and services with your explicit consent</li>
      <li>to process your transactions</li>
    </ol>
    <p>
      We use Cookies and IP data to guard against automated cyber-attacks such
      as phishing, DDoS and spam attacks. We also regularly analyze all trading
      activities to identify suspicious transactions or behaviors at the
      earliest time possible to reduce risk of loss or damage.
    </p>
  </div>
  <div>
    <h4>HOW DO WE STORE YOUR DATA?</h4>
    <p>
      We implement appropriate technical and organizational measures to protect
      your personal data against accidental or unlawful destruction, loss,
      change or damage. All personal information we collect will be stored on
      secure servers. All electronic transactions entered into via our Sites and
      the Service will be protected by SSL encryption technology. We will never
      send you unsolicited emails or contact you by phone requesting your
      account ID, password, credit or debit card information or national
      identification. The 2FA authentication method option is provided to you to
      strengthen access to your account. Any date uploaded during extended
      verification of the account is sent in an encrypted connection. Basic
      information is separated from your extended details on the level of
      databases, and only connected with a hash key to reduce the risk. Fine
      grained level of user details is specified to the level of database
      accesses, therefore limiting access to sensitive information is provided
      within the organization and set to bare minimum required to operate.
    </p>
    <p>
      The Service operates globally with servers located in the EU. If you do
      not reside in the EU, laws in your home country may be different. By using
      the Service, you acknowledge that any personal data about you, regardless
      of whether provided by you or obtained from a third party, will be hosted
      on EU servers and you authorize us to transfer, store and process your
      information to and in the EU and possibly other countries.
    </p>
  </div>
  <div>
    <h4>WILL YOUR INFORMATION BE SHARED?</h4>
    <p>
      We share your Personal data with third parties within EU//European
      Economic Area and outside under the following circumstances:
    </p>
    <ol type="I">
      <li>when you give us your consent to share;</li>
      <li>
        when we are compelled by law such as in response to a court order or
        subpoena;
      </li>
      <li>
        to protect legitimate business interests and legal rights of our company
        and members of our community. We may disclose your personal data when we
        have firm basis to believe there is ground to investigate or take any
        other action to prevent potential violations of our policies, suspected
        fraud, criminal activities and/or situations that may threaten a
        person's safety;
      </li>
      <li>when you want to process your subscriptions and transactions;</li>
      <li>
        when we want to keep you informed about critical updates that are
        important to you.
      </li>
    </ol>
    <p>
      For data transfers outside the EEA we use the Standard Contractual Clauses
      (EU Directive 95/46/EC), which meet the adequate mechanism requirements of
      all EU members.
    </p>
    <p>
      We always put in place appropriate technical and organizational measures
      to protect your personal data.
    </p>
  </div>
  <div>
    <h4>WHAT ARE YOUR PRIVACY RIGHTS?</h4>
    <p>
      If you are a citizen or resident of the EU, you have the following rights
      under the GDPR:
    </p>
    <ol>
      <li>
        Right to be Informed - you can get specific privacy information about
        our business, what date we get about you, how we process that data and
        pretty much everything else we have included in this document
      </li>
      <li>
        Right to Access - when you ask for information, we should give them to
        you and provide a copy if you so require
      </li>
      <li>
        Right to Rectification - we need to correct wrong information about you
        when you call our attention to it. The law gives us at most one month to
        act on your request for rectification
      </li>
      <li>
        Right to Erasure (Right to be Forgotten) - If you do not want to be part
        of our community any longer and would like your records to be erased
        completely, you have the right to ask us to do that and we shall do so
        as soon as possible within one month from the time request was made.
      </li>
      <li>
        Right Restrict Processing - you have the right to suppress your data. We
        can then store the data but not process it. Please note that this right
        is not absolute. You must tell us why you want to restrict processing of
        data and we will weigh your request against other factors such as our
        legal and contractual obligations among others.
      </li>
      <li>
        Right to Object to Processing - similar to the right discussed above
      </li>
      <li>
        Right to not be Evaluated based on Automated Processing - you have the
        right to be informed about any automatic processes your data is
        subjected to and the right not to be subject to the decision based
        solely on automated processing that may have significant impact on you.
      </li>
      <li>
        Right to Data Portability - allow individuals to obtain and reuse their
        personal data for their own purposes across different services. Data
        controllers must provide a system for you to move, copy or transfer data
        across different IT systems in a secure manner without affecting the
        data's usability.
      </li>
    </ol>
    <p>
      If you are not a citizen of the European Economic Area, your country may
      have other privacy laws that accords you different privacy rights but the
      GDPR is one of the most comprehensive privacy laws in the world so you're
      pretty much covered here with the rights mentioned in this section
    </p>
  </div>
  <div>
    <h4>WHAT IS OUR LEGAL BASIS FOR COLLECTING YOUR DATA?</h4>
    <p>
      In collecting your data, we rely on your consent, our legal and
      contractual obligations, the need to protect your vital interests and the
      pursuit of our legitimate interests.
    </p>
  </div>
  <div>
    <h4>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
    <p>
      Yes. Cookies are small text files created by a website that are stored in
      the user's computer either temporarily for that session only or
      permanently on the hard disk. Cookies provide a way for the website to
      recognize you and keep track of your preferences This file is neither a
      virus or spyware.
    </p>
    <p>
      You have the right to refuse our Cookies but it may affect the efficiency
      of our services. Please remember that we are committed to protecting the
      privacy of your data and only use this technology to improve customer
      experience by remembering your preferences and analyzing our website
      traffic.
    </p>
    <p>We use the following Cookies:</p>
    <table>
      <thead>
        <tr>
          <th>Cookie</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>__cf_bm (Cloudflare)</td>
          <td>
            A part of Cloudflare’s Bot Management service and helps manage
            incoming traffic that matches criteria associated with bots
          </td>
        </tr>
        <tr>
          <td>__stripe_mid (Stripe)</td>
          <td>
            A set for fraud prevention purposes and helps Stripe assess the risk
            associated with an attempted transaction
          </td>
        </tr>
        <tr>
          <td>__stripe_sid (Stripe)</td>
          <td>
            A set for fraud prevention purposes and helps Stripe assess the risk
            associated with an attempted transaction
          </td>
        </tr>
        <tr>
          <td>_fbp (Facebook)</td>
          <td>facebook pixel unique identifier</td>
        </tr>
        <tr>
          <td>_ga (Google Analytics)</td>
          <td>Used to distinguish users.</td>
        </tr>
        <tr>
          <td>_gid (Google Analytics)</td>
          <td>Used to distinguish users.</td>
        </tr>
        <tr>
          <td>_hjAbsoluteSessionInProgress (Hotjar)</td>
          <td>
            This cookie is used to detect the first pageview session of a user.
            This is a True/False flag set by the cookie.
          </td>
        </tr>
        <tr>
          <td>_hjFirstSeen (Hotjar)</td>
          <td>
            This is set to identify a new user’s first session. It stores a
            true/false value, indicating whether this was the first time Hotjar
            saw this user. It is used by Recording filters to identify new user
            sessions.
          </td>
        </tr>
        <tr>
          <td>_hjIncludedInPageviewSample (Hotjar)</td>
          <td>
            This cookie is set to let Hotjar know whether that user is included
            in the data sampling defined by the site's pageview limit.
          </td>
        </tr>
        <tr>
          <td>_hjIncludedInSessionSample (Hotjar)</td>
          <td>
            This cookie is set to let Hotjar know whether that user is included
            in the data sampling defined by the site’s daily session limit.
          </td>
        </tr>
        <tr>
          <td>_hjSessionUser_1012990 (Hotjar)</td>
          <td>
            cookie that is set when a user first lands on a page with the Hotjar
            script. It is used to persist the Hotjar User ID, unique to that
            site on the browser. This ensures that behavior in subsequent visits
            to the same site will be attributed to the same user ID.
          </td>
        </tr>
        <tr>
          <td>_hjSession_1012990 (Hotjar)</td>
          <td>
            A cookie that holds the current session data. This ensures that
            subsequent requests within the session window will be attributed to
            the same Hotjar session.
          </td>
        </tr>
        <tr>
          <td>_ym_d (Yandex Metrika)</td>
          <td>Saves the date of the user’s first site session</td>
        </tr>
        <tr>
          <td>_ym_isad (Yandex Metrika)</td>
          <td>Determines whether a user has ad blockers</td>
        </tr>
        <tr>
          <td>_ym_uid (Yandex Metrika)</td>
          <td>Used for identifying site users</td>
        </tr>
        <tr>
          <td>_ym_visorc (Yandex Metrika)</td>
          <td>Allows Session Replay to function correctly</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <h4>WHAT IF YOU DON'T WANT COOKIES TO BE SET?</h4>
    <p>
      Most browsers allow you to manage cookies. Some browsers allow you to
      manage cookie preferences for each particular website also.
    </p>
    <p>
      You may opt-out of third party cookies from Google Analytics on its
      website.
    </p>
    <p>
      <strong>Please note:</strong> if you limit the cookies' premises, you may
      worsen your overall user experience and/or lose the ability to access the
      services, since it will no longer be personalized to you.
    </p>
  </div>
  <div>
    <h4>WHAT IS OUR POLICY REGARDING THIRD PARTY WEBSITES?</h4>
    <p>
      All our partners and contractors who provide services on our behalf or
      relating to our web site are required to respect privacy of data held by
      us but we cannot guarantee the safety of information that may be gathered
      about you when you click links to other websites that you may find in our
      site such as advertisers. We are not responsible for the content or
      privacy and security practices and policies of any third parties,
      including other websites, services or applications that may be linked to
      or from our site. You should review the policies of such third parties and
      contact them directly to answer your questions.
    </p>
  </div>
  <div>
    <h4>HOW LONG DO WE KEEP YOUR DATA?</h4>
    <p>
      The data retention period is 6 years, unless you send us a separate
      request before the expiration of this period, stop storing your personal
      data. Anyway, we will not retain your personal data for longer than
      required.
    </p>
  </div>
  <div>
    <h4>HOW DO WE TRANSFER YOUR DATA INTERNATIONALLY?</h4>
    <p>
      Our servers are located in the EU. If you are accessing our Site from
      outside, then please be aware that we are transferring and storing your
      personal data in our facilities and the facilities of our affiliates
      located in the EU.
    </p>
    <p>
      For data transfers outside the EEA we use the Standard Contractual Clauses
      (EU Directive 95/46/EC), which meet the adequate mechanism requirements of
      all EU members.
    </p>
  </div>
  <div>
    <h4>DO WE COLLECT DATA FROM MINORS?</h4>
    <p>
      No, we do not allow minors to use our services. To ensure that no minors
      engage our staking services we require submission of identification
      documents.
    </p>
  </div>
  <div>
    <h4>HOW DO WE COMMUNICATE CHANGES IN THIS POLICY TO YOU?</h4>
    <p>
      In case of changes in our privacy policy, we will communicate the same to
      you via Email and/or announcements on our websites.
    </p>
    <p>
      You have the right to appeal to your local data protection authority if
      you feel that we have not responded to your requests to solve a problem
    </p>
  </div>
  <div>
    <h4>HOW TO CONTACT US?</h4>
    <p>You may reach us through the following:</p>
    <p>1) website: Elite-mining.com</p>
    <p>2) Email: contactgdpr@Elite-mining.com</p>
    <p>
      3) by post: Elite mining OÜ, Väike-Paala tn 2, Lasnamäe linnaosa, 11415
      Tallinn, Harju maakond, Estonia
    </p>
    <p>
      <em>last updated: 23rd of June 2022</em>
    </p>
  </div>
</div>;
<Footer/>
    </div>
  )
}

export default Privacy
