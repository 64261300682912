import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,TablePagination,makeStyles } from "@material-ui/core";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../FirebaseConfig';
import { green, red } from "@material-ui/core/colors";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(3),
      margin: "10px",
      maxWidth: "100%",
      backgroundColor:"rgba(17, 25, 40, 0.75)"
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: theme.spacing(2),
    },
    input: {
    
     margin:theme.spacing(2),
     color:'black !important',
     padding:"3px"
    },
    button: {
      marginTop: theme.spacing(2),
    },
    table: {
        maxWidth: "90%",
        margin:'auto',
        marginLeft:"20px",
        '@media (max-width: 550px)': {
            maxWidth: '100%',
            margin:'auto'
          },
      },
      success: {
        color: green[500],
      },
      pending: {
        color: red[500],
      },
  }));
const TransactionsTable = () => {
    const classes=useStyles()
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [user] = useAuthState(auth);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/transactions.json`);
        const data = response.data;
        setTransactions(data ? Object.values(data) : []);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, transactions.length - (page - 1) * rowsPerPage);

  return (
    <TableContainer component={Paper} elevation={0} className={classes.table}>
      <Table aria-label="transactions table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">Transaction Amount($)</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Asset</TableCell>
            <TableCell align="right">Payment Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions
            .slice((page - 1) * rowsPerPage, page * rowsPerPage).reverse()
            .map((transaction,ind) => (
              <TableRow key={ind}>
                <TableCell> {moment(transaction.date).format("YYYY-MM-DD")}</TableCell>
                <TableCell align="right">{transaction.amount}</TableCell>
                <TableCell align="right">
                  <span style={{ color: transaction.status === "successful" ? "green" : "red" }}>{transaction.status}</span>
                </TableCell>
                <TableCell align="right">{transaction.asset}</TableCell>
                <TableCell align="right">{transaction.paymentType.name||"N/A"}</TableCell>
              </TableRow>
            ))}
          
           {transactions.length === 0 && (
            <TableRow>
              <TableCell colSpan={5}>No transactions available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[4, 8, 12]}
        component="div"
        count={transactions.length}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default TransactionsTable;
