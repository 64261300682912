import React, { useState } from 'react';
 // Import the CSS file for styling

const NoticeModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="notice-modal-overlay" onClick={handleClose}>
          <div className="notice-modal">
            <button className="close-button" onClick={handleClose}>
              &times;
            </button>
            <h2>NOTICE TO INVESTORS</h2>
            <p>Our platform is safe and user datas are safe and not exposed to third party entities. We offer a profitable investment plan that returns great profits in the best of time with little to no risk attached. We have a 24/7 active support team to help you out on any complaints</p>
          </div>
        </div>
      )}
    </>
  );
};

export default NoticeModal;