import React, { useState,useEffect } from 'react';
import {db,auth} from "../FirebaseConfig"
import {  ref, set } from "firebase/database";
import {
    getAuth,
    createUserWithEmailAndPassword,
    updateProfile,
  } from "firebase/auth";

function SignUpForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [backupEmail, setBackupEmail] = useState('');
  const [address, setAddress] = useState('');
  const [occupation, setOccupation] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState('');

  const [infoMsg, setInfoMsg] = useState('');
const[code,setCode]=useState()
  const [initialLoading, setInitialLoading] = useState(false);
  const [initialError, setInitialError] = useState('');

  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      // create user account
    
   
      
        const {user} = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        
      // create user data object
      const userData = {
        firstName,
        lastName,
        country,
        city,
        zip,
        
        phone,
        profitEarned:0,
        balance:0,
        email,
        status:"Unverified",
        occupation,
        backupEmail,
        usedcoupon:false,
       crypto:{
        "ZCH": {
          "balance": 0,
          "deposited": 0,
          "profit": 0,
          "Hashrate":0,
          "increase":true,
        },
        
        "BTC": {
          "balance": 0,
          "deposited": 0,
          "profit": 0,
          "Hashrate":0,
          "increase":true,
        },
        "LTC": {
          "balance": 0,
          "deposited": 0,
          "profit": 0,
          "Hashrate":0,
          "increase":true,
        },
        "XMR": {
          "balance": 0,
          "deposited": 0,
          "profit": 0,
          "Hashrate":0,
          "increase":true,
        },
        "ZCH": {
          "balance": 0,
          "deposited": 0,
          "profit": 0,
          "Hashrate":0,
          "increase":true,
        },
        "SHIB": {
          "balance": 0,
          "deposited": 0,
          "profit": 0,
          "Hashrate":0,
          "increase":true,
        },
        "ETC": {
          "balance": 0,
          "deposited": 0,
          "profit": 0,
          "Hashrate":0,
          "increase":true,
        }
      }
      };

      // store user data in Firebase realtime database
    console.log(user)
      await set(ref(db, `users/${user.uid}`), {
        userData
      });
      // reset form fields
      setEmail('');
      setPassword('');
      setFirstName('');
      setLastName('');
      setCountry('');
      setCity('');
      setZip('');
      setPhone('');
      
      console.log(user)
     alert('User signed up successfully!');
     window.location.href="/dashboard";
    } catch (error) {
      console.error(error);
    }
  };
  const isStep1Valid = () => {
    return email !== '' && password !== '' && confirmPassword !== '' && password === confirmPassword;
  };

  const handleNext = () => {
    if (currentPage === 1 && isStep1Valid()) {
      setCurrentPage(2);
    }
  };

  const handleBack = () => {
    if (currentPage === 2) {
      setCurrentPage(1);
    }
  };
  

  useEffect(() => {
  
  }, );



  return (
    <div className="signup-form wrapcontainer">
       <section className="wrapper">
        <div className="item__left">
          <img className="item__left_img" src="https://static.mycointainer.com/auth/194d961c888828be478f.png" />
          <div className="item__left_content">
            <h3 className="item__left_title">{/*hj*/}Live a Rewarding Life</h3>
          </div>
        </div>
        <div className="item__right">
          <div className="container">
            <div onClick={()=>{window.location.href="/"}} > <img src={require("../components/EMLOGO.png")} width={200} style={{margin:'auto',padding:"10px",backgroundColor:'black'}}/></div>
            {/*hj*/}<div className="content2">
              <h1 className="title">{/*hj*/}Sign up</h1>
              <form onSubmit={handleSignup}>
     
      {currentPage === 1 && (
       
        <div className="form-step boxx">
          <label>First Name:</label>
          <input type="text" required  value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          <label>Last Name:</label>
          <input type="text" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <label>Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <label>Confirm Password:</label>
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
          
          <button onClick={handleSignup} disabled={!isStep1Valid()}>Register</button>
          <div><span>Already have an account? <a href="/login">login here</a></span></div>
          <div><span>By signing Up you agree to our terms of service <a href="/login"> terms of service</a></span></div>

        </div>
      )}
      {currentPage === 2 && (
        <div className="form-step boxx">
          <label>First Name:</label>
          <input type="text" required  value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          <label>Last Name:</label>
          <input type="text" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
          <label>Country:</label>
          <input type="text"required  value={country} onChange={(e) => setCountry(e.target.value)} />
          <label>City:</label>
          <input type="text" required value={city} onChange={(e) => setCity(e.target.value)} />
          <label>Zip:</label>
          <input type="text" required value={zip} onChange={(e) => setZip(e.target.value)} />
          <label>Phone:</label>
          <input type="text" required value={phone} onChange={(e) => setPhone(e.target.value)} />
          <label>Backup Email:</label>
          <input type="email" value={backupEmail} onChange={(e) => setBackupEmail(e.target.value)} />
          <label>Address:</label>
          <input type="text" required value={address} onChange={(e) => setAddress(e.target.value)} />
          <label>Occupation:</label>
          <input type="text" value={occupation} onChange={(e) => setOccupation(e.target.value)} />
        <div style={{display:'flex',justifyContent:'space-between'}}> <button onClick={handleBack}>Back</button>    <button type="submit">Submit</button></div> 
       </div>
       
      )}
      </form>
         
              <p className="signup">
                {/*hj*/}Already have an Account?
                <a href="/login">{/*hj*/}Sign in</a>
              </p>
              {loginError!==''&&(
                    <div className='error-msg'>{loginError}</div>
                  )}

                  {/* show info msg */}
                  {infoMsg!==''&&(
                    <div className='info-msg'>{infoMsg}</div>
                  )}
            </div>
            <p className="footer">
              {/*hj*/}© Elite mining· By signing up you agree to our
              <a href="/terms-services" className="privacy-terms-btn" router-ignore>
                {/*hj*/}Terms &amp; Conditions
              </a>
            </p>
          </div>
        </div>
         </section>
         
    </div>
 )
}

export default SignUpForm;