import React from 'react'
import Footer from '../components/footer';
import FAQSection from '../components/faq';
import Navbar from '../components/Navbar';
function contact() {
  const faqs= [
    {
      "question": "What is Elite Minng?",
      "answer": "Elite-Mining is a Cryptocurrency cloud mining service that offers an easy and safe way to purchase hashpower without having to deal with complex hardware and software setup. We offer hosted cryptocurrency mining services and a variety of mining related solutions to small and large scale customers. The combination of our algorithmic trading framework, mining infrastructure, and proprietary mining farm-management software, Elite Hive, quickly made us an industry leader. Our team of mining experts with extensive knowledge of the digital currency sector specializes in building the most efficient and reliable mining facilities. Elite Mining is also the founding partner of Logos Fund, the first ever Bitcoin Mining Fund, which targets professional investors looking to gain access to Bitcoin’s and various other digital assets’ potential. Our service was founded by the end of 2013 and with now over 2.000.000 users we are the world‘s leading multi-algorithm cloud mining service."
    },
    {
      "question": "How does Bitcoin mining work with us?",
      "answer": "It’s quick and very easy! As soon as we receive your payment your contract will be added to your profile, and you can immediately start mining. Depending on the blockchain algorithm you select and the associated mining service agreement you enter into, you can either mine native cryptocurrencies directly or allocate your hashpower to other cryptocurrencies (marked with AUTO), and even choose a specific allocation for them. For example: 60% LTC, 20% BTC and 20% DOGE. The first mining output is released after 48 hours, and then a daily mining output will follow. Remark: Every day of mining will be processed and sent to your account in the following 24 hours after the mining day is over. Please refer to section “When do I get my mining output?” for more details."
    },
    {
      "question": "What s the mining Performance? Why not mine your self",
      "answer": "Besides the fact that we ourselves mine with the very same hardware that we offer to our clients, our capital is limited. We believe that Bitcoin and Altcoin mining is one of the best ways to receive Cryptocurrencies, however, we do not want to “put all our eggs in one basket”."
    },
    {
      "question": "What coins can i mine with what algorithm?",
      "answer": "You are able to mine Bitcoin and various altcoins directly via our mining allocation page*. The availability of cryptocurrencies you can mine depends on the contract you have chosen. You must allocate your hashpower in order to determine the cryptocurrency received for your mining output. If no allocation has been made, the mining output will default to the following for the given blockchain algorithm: SHA-256 contract - Bitcoin X11 contract - Dash Ethash contract - Ethereum CryptoNight contract - Monero/Monero Classic Scrypt - Litecoin Equihash - Zcash (*) “Elite Mining Advanced Allocation” (special feature): It allows you to get mining outputs in many different coins even if they are not mined directly by a certain algorithm. For example, you can get mining outputs in BTC while mining with an X11 algorithm! That is possible by mining the DASH coin directly, which is then automatically swapped to BTC by our algorithmic framework. The Allocation function is designed for customers to receive delivery of their mining results in their preferred cryptocurrency. We call it “mining BTC the smart way”. The same technique is also used to get mining results in LTC with a SHA-256 contract, etc."
    },
    {
      "question": "Whichpools are used for mining?",
      "answer": "We do not publish a list of pools we are using. Our main criteria for a good pool are: reliability, fee structure and reject rate. Going forward we will solo-mine a few coins (and pass the fee savings to our users!). Our internal policy is: “be a good crypto citizen”. This means, that we will at least use two different pools (in some cases we use up to four) for each coin. This is to preserve the decentralized nature of the crypto networks! If we become aware that a pool is getting close to 50% share, we will switch away from it and use a backup instead."
    }
  ]

  return (
    <div>
     <Navbar/>
     <section className="">
      <div className="planheader cont3"></div>
      <div className="cont ">
        <h1 className="header">Help & Support</h1>
        <p className="text">Follow us on our social channels to keep up-to-date with the latest news and offers.</p>
       
      </div>
    </section>
    <div height={735} className="sc-iBYQkv fStxHs">
  <div height={735} className="sc-ipEyDJ bEpVGt" />
  <div className="sc-ftTHYK kvHrOR">
    <div color="var(--color--primary)" className="sc-pyfCe eASrxj">
      <h1 className="sc-gWoSmj dssSIF">
      Need assistance at   <span>any time?</span>
      </h1>
    </div>
    <div className="sc-jrcTuL fqlHZJ">
    Contact our 24-hour customer care staff.
    </div>
   
  </div>
  <div className="sc-hJVpLM cEXGkA">
    <img
      alt=""
      src="https://i.postimg.cc/d17kh5Lr/12084788-20943941-ai.png"
    />
  </div>
</div>
       <section className="contact_section layout_padding" style={{backgroundColor:'aliceblue'}} >
    <div className="container contact_heading">
      <h2>
        Contact Us
      </h2>
      <p>
        OUR OFFICES ARE OPEN 24/7
      </p>
    </div>
    <div className="container">
       
  
      
          <div className="form-group  col-md-12"style={{width:'80%'}}>
            <label for="inputName4">Name</label>
            <input type="text" className="form-control" required id="inputName4"/>
          </div>
          <div className="form-group  col-md-12"style={{width:'80%'}}>
            <label for="inputEmail4">Email</label>
            <input type="email" className="form-control" required id="inputEmail4"/>
          </div>

    
      
          
          

    
        <div className="form-group" style={{width:'70%'}}>
          <label for="inputMessage">Message</label>
          <textarea className="form-control" id="inputMessage" col="30" placeholder=""></textarea>
        </div>
    </div>

    <div className="d-flex justify-content-center">
      <button type="submit" className="" onClick={(e)=>{e.preventDefault()
      alert("form submitted thank you we will get back to you shortly")}} >Send</button>
    </div>
   
   
  </section>
  <section>
    <center style={{margin:'3%'}}> <h1>F.A.Q</h1></center>
    <div style={{ display: "flex", justifyContent: "center", marginTop: 32 }}> <FAQSection faqs={faqs} /></div>
 
 </section>
 <div className="sc-cwSeag emhsJJ">
  <img
    alt=""
    src="https://www.mycointainer.com/landing-main/media/footer-texture.d161594c.svg"
    className="sc-lllmON fgViNB"
    style={{ height: 593, width: "100%" }}
  />
  <div className="sc-iJnaPW gvcNWt">
    <div className="sc-gikAfH foHjIi">
      Get started today with as little as $100 and Mine <span></span>{" "}
      cryptocurrencies
    </div>
    <div className="sc-ezOQGI deCcyC">Download our app on Android or iOS(Coming this December)</div>
    <div className="sc-bYMpWt jiFSkU">
      <a
        
      >
        <img alt="" src="https://www.mycointainer.com/landing-main/media/app-store.10182d30.svg" />
      </a>
      <a
       
      >
        <img alt="" src="https://www.mycointainer.com/landing-main/media/play-market.f5ed18aa.svg" />
      </a>
    
    </div>
  </div>
  <img
    loading="lazy"
    alt=""
    src="https://i.postimg.cc/LsW6qkjg/image-1.jpg"
    className="sc-kMjNwy ejGqCd"
  />
</div>

<Footer/>
    </div>
  )
}

export default contact
