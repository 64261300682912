import React, { useState, useEffect ,useRef} from "react";
import { useSearchParams } from 'react-router-dom'
import axios from "axios";
import { auth } from '../FirebaseConfig';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useLocation } from 'react-router-dom';
const AdminForm = () => {
  const [data, setData] = useState(null);
  const [data3, setData3] = useState({});
  const [searchParams] = useSearchParams();
  const [userData, setUserData] = useState(null);
  const [usedcoupon, setusedcoupon] = useState(false);
  const [status, setStatus] = useState('Unverified');
  const [balance, setBalance] = useState(0);
  const [profitEarned, setProfitEarned] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const[isAmin,setisAdmin]=useState()
  const[tstatus,settstaus]=useState("")
  const[plansData,setplansData]=useState([])
  const statusRef=useRef(null)
  const myQueryParam = searchParams.get('userId');
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [editingItemId, setEditingItemId] = useState(null);
  const[kycdata,setkycdata]=useState()
  const [wallets, setWallets] = useState({});
//4Fa5DzYPgvU8p8pMM8bJYrUzVVQ2



useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged(user => {
    if (user&& user.uid!="4jUyqtiYOsMuIP1hKBPJMGLPZxG2") {
      // User is signed in.
      navigate("/login")
          } }
  );

  return () => {
    unsubscribe(); // Unsubscribe from the auth state listener when component unmounts
  };
}, [navigate]);

useEffect(() => {
  fetch(`https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/userData/wallets.json`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch wallets data');
      }
      return response.json();
    })
    .then(data => {
      setWallets(data);
    })
    .catch(error => {
      console.error('Error fetching wallets:', error);
    });
}, []);
useEffect(() => {
  
  
  axios
  .get(
    `https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/userData/transactions.json`
  )
  .then((response) => {
    const transactionsArray = Object.entries(response.data).map(
      ([key, value]) => ({ id: key, ...value })
    );
    setTransactions(transactionsArray);
  })
  .catch((error) => {
    console.log(error);
  });

  
}, [user,navigate]);
useEffect(() => {
  

  fetch(
    `https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/userData/withdrawals.json`
  )
    .then((response) => response.json())
    .then((data) => {
      const withdrawalsData = [];
      for (const key in data) {
        withdrawalsData.push({
          id: key,
          ...data[key],
        });
      }
      setWithdrawals(withdrawalsData);
    })
    .catch((error) => console.log(error));
}, []);

const editAddress = (currency) => {
  const newAddress = prompt(`Enter new address for ${currency}`);
  if (newAddress !== null) {
    const updates = {};
    updates[`users/${myQueryParam}/userData/wallets/${currency}/address`] = newAddress;

    // Send PATCH request to update the address
    fetch(`https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/userData/wallets/${currency}/.json`, {
      method: 'PATCH',
      body: JSON.stringify({"address":newAddress})
    })
    .then(response => {
      if (response.ok) {
        alert('Address updated successfully');
        // Update local state if necessary
        setWallets(prevWallets => ({
          ...prevWallets,
          [currency]: {
            ...prevWallets[currency],
            address: newAddress
          }
        }));
      } else {
        alert('Failed to update address');
      }
    })
    .catch(error => {
      console.error('Error updating address:', error);
      alert('Failed to update address');
    });
  }
};
const handleStatusChange2 = (transactionId, newStatus) => {
  axios
    .patch(
      `https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/userData/transactions/${transactionId}.json`,
      { status: newStatus }
    )
    .then(() => {
      alert(' update done')
    })
    .catch((error) => {
      console.log(error);
    });
};

const handleStatusChange = (e, id) => {
  const updatedWithdrawals = [...withdrawals];
  const withdrawalIndex = updatedWithdrawals.findIndex(
    (withdrawal) => withdrawal.id === id
  );
  updatedWithdrawals[withdrawalIndex].status = e.target.value;
  setWithdrawals(updatedWithdrawals);
};

const handleCommentChange = (e, id) => {
  const updatedWithdrawals = [...withdrawals];
  const withdrawalIndex = updatedWithdrawals.findIndex(
    (withdrawal) => withdrawal.id === id
  );
  updatedWithdrawals[withdrawalIndex].comment = e.target.value;
  setWithdrawals(updatedWithdrawals);
};
const handleEditClick = (itemId) => {
  setEditingItemId(itemId);
};

const handleSaveClick = () => {
  // Update data in Firebase using Firebase REST API
  axios.patch(`https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/userData/active-plans/${plansData[editingItemId].id}.json`, {
    status: plansData[editingItemId].status,
    hashrate: plansData[editingItemId].hashrate,
  })
    .then(() => {
      // Successfully updated data
      setEditingItemId(null);
    })
    .catch((error) => {
      console.error('Error updating data:', error);
    });
};

const handleStatusChange3 = (event, itemId) => {
  // Update the status in the local state
  setplansData({
    ...plansData,
    [itemId]: {
      ...plansData[itemId],
      status: event.target.value,
    },
  });
};

const handleHashrateChange = (event, itemId) => {
  // Update the hashrate in the local state
  setplansData({
    ...plansData,
    [itemId]: {
      ...plansData[itemId],
      hashrate: event.target.value,
    },
  });
};
const handleUpdateWithdrawals = () => {
  withdrawals.forEach((withdrawal) => {
    fetch(
      `https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/userData/withdrawals/${withdrawal.id}.json`,
      {
        method: "PATCH",
        body: JSON.stringify({
          status: withdrawal.status,
          comment: withdrawal.comment,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => console.log(error));
  })
  
  alert('done');
};
useEffect(() => {
  fetch( ` https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/userData/.json`)
  .then(response => response.json())
  .then(data => {
    setUserData(data);
    setusedcoupon(data.usedcoupon);
    setStatus(data.status);
    setBalance(data.balance);
    setProfitEarned(data.profitEarned);
  })
  .catch(error => console.error(error));


}, []);
  useEffect(() => {
    fetch(
     ` https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/.json`
    ) // replace <your-project-id> with your Firebase project ID
      .then((response) => response.json())
      .then((data) => {setData(data.userData)
        setkycdata(data.kycForms.data)});
  }, []);
  useEffect(() => {
    fetch(
     ` https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/userData/active-plans.json`
    ) // replace <your-project-id> with your Firebase project ID
      .then((response) => response.json())
      .then((data) => {
        const plansArray = Object.entries(data).map(([id, plan]) => ({ id, ...plan }));
        setplansData(plansArray)});
  }, []);

  const handleUpdate2 = () => {
    // update user data in Firebase Realtime Database using REST API
    const newData = { usedcoupon, status, balance, profitEarned };
    fetch(` https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/userData/.json`, {
      method: 'PATCH',
      body: JSON.stringify(newData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Data updated successfully:', data);
        alert(` updated successfully!`);
        // optional: display a success message to the user
      })
      .catch(error => console.error(error));
  };
  const handleUpdate = (cryptoName) => {
    const newData = { ...data };
    
console.log("nd",newData)
    fetch(
      ` https://grandstaking-default-rtdb.firebaseio.com/users/${myQueryParam}/userData/.json`,
      {
        method: "PATCH",
        body: JSON.stringify(newData),
      }
    ) // replace <your-project-id> with your Firebase project ID
      .then((response) => {
        if (response.ok) {
          alert(`${cryptoName} updated successfully!`);
        }
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  return (
    <div className="admin-form" style={{maxWidth:"70%",margin:'auto',display:'flex',justifyContent:'center',flexDirection:"column"}}>
<div>
  <h3>TOTAL BALANCE& PROFIT</h3>
      {userData ? (
        <form>
          <label htmlFor="usedcoupon">Used Coupon:</label>
          <select id="usedcoupon" value={usedcoupon} onChange={e => setusedcoupon(e.target.value === 'true')}>
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select><br />
          
          <label htmlFor="status">Status:</label>
          <select id="status" value={status} onChange={e => setStatus(e.target.value)}>
            <option value="Unverified">Unverified</option>
            <option value="In review">In review</option>
            <option value="Verified">Verified</option>
          </select><br />
          
          <label htmlFor="balance">Balance:</label>
          <input id="balance" type="number" value={balance} onChange={e => setBalance((e.target.value))} /><br />
          
          <label htmlFor="profitEarned">Profit Earned:</label>
          <input id="profitEarned" type="number" value={profitEarned} onChange={e => setProfitEarned((e.target.value))} /><br />
          
          <button type="button" onClick={handleUpdate2}>Update</button>
        </form>
      ) : (
        <p>Loading user data...</p>
      )}
    </div>
      {data && (
        <>
          <h2>Crypto Editing Form</h2>
          <table>
            <thead>
              <tr>
                <th>Crypto</th>
                <th>Balance</th>
                <th>Deposited</th>
                <th>Profit</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(!!data&&data.crypto).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>
                    <input
                      type="number"
                      value={value.balance}
                      onChange={(e) => {
                        const newValue = { ...value };
                        newValue.balance = e.target.value;
                        setData({
                          ...data,
                          crypto: {
                            ...data.crypto,
                            [key]: newValue,
                          },
                        });
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={value.deposited}
                      onChange={(e) => {
                        const newValue = { ...value };
                        newValue.deposited = e.target.value;
                        setData({
                          ...data,
                          crypto: {
                            ...data.crypto,
                            [key]: newValue,
                          },
                        });
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={value.profit}
                      onChange={(e) => {
                        const newValue = { ...value };
                        newValue.profit = e.target.value;
                        setData({
                          ...data,
                          crypto: {
                            ...data.crypto,
                            [key]: newValue,
                          },
                        });
                      }}
                    />
                  </td>
                  <td>
                    <button onClick={() => handleUpdate(key)}>Update</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div><h3>Withdrawals</h3>
          <div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Amount</th>
            <th>Asset</th>
            <th>Date</th>
            
            <th>Status</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {withdrawals.map((withdrawal) => (
            <tr key={withdrawal.id}>
              <td>{withdrawal.id}</td>
              <td>{withdrawal.amount}</td>
              <td>{withdrawal.asset}</td>
              <td>{withdrawal.date}</td>
             
              <td>
                <select
                  value={withdrawal.status}
                  onChange={(e) => handleStatusChange(e, withdrawal.id)}
                >
                  <option value="pending">Pending</option>
                  <option value="successful">Successful</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  value={withdrawal.comment || ""}
                  onChange={(e) => handleCommentChange(e, withdrawal.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleUpdateWithdrawals}>Update</button>
    </div>
          
          </div>
          <div>
            <h1 style={{textAlign:'center'}}>WALLETS</h1>
      {!!wallets && Object.entries(wallets).map(([currency, wallet]) => (
        <div key={currency} style={{display:'flex',justifyContent:"center",gap:'10px',border:"1px solid teal",padding:"5px"}}>
          <p>Currency: {currency}</p>
          <p>Address: {wallet.address}</p>
          <button onClick={() => editAddress(currency)}>Edit</button>
        </div>
      ))}
    </div>
          <div>
            <h3>Transactions</h3>
      <table>
        <thead>
          <tr>
         
            <th>Amount</th>
            <th>Asset</th>
            <th>Date</th>
            <th>Payment Type</th>
     
            <th>NewStatus</th>
            
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id}>
          
              <td>{transaction.amount}</td>
              <td>{transaction.asset}</td>
              <td>{transaction.date}</td>
              <td>
                {transaction.paymentType.name} -
                {transaction.paymentType.walletAddress}
              </td>
            
              <td>
                <select
                ref={statusRef}
                  value={transaction.status}
                  onChange={(e)=>{
                    setTransactions((prevTransactions) =>
        prevTransactions.map((transactionn) =>
          transactionn.id === transaction.id
            ? { ...transactionn, status: e.target.value }
            : transactionn
        )
      );
                  }}
                >
                  <option value="pending">Pending</option>
                  <option value="successful">Successful</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </td>
             
              <td>
                <button onClick={()=>{  handleStatusChange2(transaction.id,statusRef.current.value )}}>Update</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div> <center><h1> kyc Files (click to view)</h1></center></div>
{
  !!kycdata?<div style={{display:'flex',flexDirection:'column'}}>
<a href={kycdata.identityProofUrl} target="_blank">Identity proof</a>
<a href={kycdata.incomeProofUrl} target="_blank">Income proof</a>
<a href={kycdata.residencyProofUrl} target="_blank">Selfie proof</a>
  </div>:<p>no kyc submitted for this user</p>
}

    <div> <center><h1>EDIT USER MINING PLANS</h1></center>
   
      <ul>
        {Object.keys(plansData).map((itemId) => (
          <li key={itemId}>
            <div>ID: {plansData[itemId].id}</div>
           
            <div>Price: {plansData[itemId].price}</div>
            <div>Status:
              <select
                value={plansData[itemId].status}
                onChange={(event) => handleStatusChange3(event, itemId)}
                disabled={editingItemId !== itemId ? 'disabled' : ''}
              >
                <option value="requested">Requested</option>
                <option value="active">Active</option>
                <option value="expired">Expired</option>
              </select>
            </div>
            <div>Hashrate:
              <input
                type="number"
                value={plansData[itemId].hashrate}
                onChange={(event) => handleHashrateChange(event, itemId)}
                disabled={editingItemId !== itemId ? 'disabled' : ''}
              />
            </div>
            <div>
              {editingItemId === itemId ? (
                <button onClick={handleSaveClick}>Save</button>
              ) : (
                <button onClick={() => handleEditClick(itemId)}>Edit</button>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
        </>
      )}
    </div>
  );
};

export default AdminForm;


