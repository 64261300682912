import React, { useState,useEffect } from 'react';
import { auth } from '../FirebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
const pricingData = {
  bitcoin: {
    plans: [
      {
        title: 'BRONZE',
        price: '$1,500',
        id: "BTC-BRONZE",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'GOLD',
        price: '$3,500',
        id: "BTC-GOLD",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'PLATINUM',
        price: '$7,500',
        id: "BTC-PLATINUM",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'DIAMOND',
        price: '$10,300',
        id: "BTC-DIAMOND",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      }
    ],
  },
  litecoin: {
    plans: [
      {
        title: 'BRONZE',
        price: '$600',
        id: "LTC-BRONZE",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'GOLD',
        price: '$1,500',
        id: "LTC-GOLD",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'PLATINUM',
        price: '$3,500',
        id: "LTC-PLATINUM",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'DIAMOND',
        price: '$5,600',
        id: "LTC-DIAMOND",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      }
    ],
  },
  monero: {
    plans: [
      {
        title: 'BRONZE',
        price: '$350',
        id: "XMR-BRONZE",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'GOLD',
        price: '$1,000',
        id: "XMR-GOLD",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'PLATINUM',
        price: '$2,400',
        id: "XMR-PLATINUM",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'DIAMOND',
        price: '$2,500',
        id: "XMR-DIAMOND",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      }
    ],
  },
  zcash: {
    plans: [
      {
        title: 'BRONZE',
        price: '$200',
        id: "ZCH-BRONZE",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'GOLD',
        price: '$750',
        id: "ZCH-GOLD",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'PLATINUM',
        price: '$1,800',
        id: "ZCH-PLATINUM",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'DIAMOND',
        price: '$1,900',
        id: "ZCH-DIAMOND",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      }
    ],
  },
  shiba: {
    plans: [
      {
        title: 'BRONZE',
        price: '$200',
        id: "SHIB-BRONZE",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'GOLD',
        price: '$750',
        id: "SHIB-GOLD",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'PLATINUM',
        price: '$1,800',
        id: "SHIB-PLATINUM",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'DIAMOND',
        price: '$1,900',
        id: "SHIB-DIAMOND",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      }
    ],
  },
  ethereum_classic: {
    plans: [
      {
        title: 'BRONZE',
        price: '$499',
        id: "ETC-BRONZE",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'GOLD',
        price: '$1,499',
        id: "ETC-GOLD",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'PLATINUM',
        price: '$4,499',
        id: "ETC-PLATINUM",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
        ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      },
      {
        title: 'DIAMOND',
        price: '$7,499',
        id: "ETC-DIAMOND",
        description: 'highly rated by our users, perfect starting options',
        features: ['Advanced 28nm ASIC technology', 
         ' Daily Payouts ', 'SHA-256 Mining Algorithm', 'Maintenance Fees apply'],
        hashrate: 0,
        status: "requested"
      }
    ],
  },

  // Add similar data for other cryptocurrencies
}



function PricingTable({fromdash,userId}) {
  const [selectedCrypto, setSelectedCrypto] = useState('bitcoin');
  const selectedPlans = pricingData[selectedCrypto].plans;
  const [newPlan, setNewPlan] = useState({
   // Set the status to 'active' or any other value
    // Add other properties of your plan here
  });
  const [user] = useAuthState(auth);
  const uid=window.localStorage.getItem('auth')
  
  const handleCryptoChange = (event) => {
    setSelectedCrypto(event.target.value);
  };
  const [activePlans, setActivePlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
 
  useEffect(() => {
    if (!uid) {
      setIsLoading(false);
      return;
    }

    // Firebase Realtime Database REST API endpoint
    const apiUrl = `https://grandstaking-default-rtdb.firebaseio.com/users/${uid}/userData/active-plans.json`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // Convert the data object to an array of plans
          const plansArray = Object.entries(data).map(([id, plan]) => ({ id, ...plan }));
        
          // Filter the plans to find active ones
          const activePlans = plansArray.filter((plan) => plan.status === 'active');
          console.log("arr",activePlans)
          setActivePlans(activePlans);
        } else {
          // No active plans found
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, [uid]);
  const handleUpdate = (plan,userId,id) => {
  
  
    setNewPlan(plan)
  console.log("arr2",JSON.stringify(activePlans))
 
  let run=true
activePlans.forEach((object, index) => {
    const status = object.status;
    if(id===object.id&& status==="active"){
      alert("this plan is already active!!!")
      run=false;
    }
    if(id===object.id&& status==="requested"){
      alert("this plan has already been requested. Please wait for approval or contact support")
      run=false;
    }
      
  });

    // Construct the Firebase Realtime Database REST API URL
    
    const apiUrl = `https://grandstaking-default-rtdb.firebaseio.com/users/${uid}/userData/active-plans/${id}.json`;
if(run){
  fetch(apiUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(plan),
  })
    .then((response) => response.json())
    .then((data) => {
      alert('Plan Requested..We will notify you if mining has commenced !');
      // You can perform additional actions here if needed
    })
    .catch((error) => {
      console.error('Error updating/writing object:', error);
    });
}
    
  };

  return (
      <div>
          <div className="crypto-select">
      <h2 >Select Mining Asset</h2>
      <br/>
      <select id="crypto" onChange={handleCryptoChange} value={selectedCrypto} className='styled-select'>
        <option value="bitcoin">Bitcoin</option>
        <option value="litecoin">Litecoin</option>
        <option value="monero">Monero</option>
        <option value="zcash">Zcash</option>
        <option value="shiba">Shiba</option>
        <option value="ethereum_classic">Ethereum Classic</option>
        

        {/* Add options for other cryptocurrencies */}
      </select>
    </div> 
    <div className="wrapper">
     
      
      <div className="pricing-table">
        {selectedPlans.map((plan, index) => (
          <div key={index} className="pricing-box">
            <h2>{plan.title}</h2>
            <span className="price">{plan.price}</span>
            <p className="description">{plan.description}</p>
            <span className="pricing-table-divider"></span>
           { !!fromdash ?<a className="btn" onClick={()=>{
             handleUpdate(plan,userId,plan.id)
           }}>
       <b>Start Mining</b>
            </a>:<a className="btn" href="/register">
       <b>Get started</b>
            </a>}
            <span className="pricing-table-divider"></span>
            <ul>
              {plan.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div></div> 
      <div style={{marginTop:'30px',marginBottom:'30px'}}>
        <br />
        <br />
        <section className="section-container arrange">
          <p style={{width:'80%',textAlign:'center',margin:'auto'}}>⏰</p>
 <p style={{width:'80%',textAlign:'center',padding:'5%',margin:'auto'}}>30 months of guaranteed runtime Your mining services will run for 30 months, period - even in cases where your daily mining reward falls below the Maintenance Fees. This way, you will always have the potential to produce cryptocurrencies as market conditions improve. The daily mining outputs are variable and are determined by these factors: the mining difficulty, the Bitcoin vs USD exchange rates, the Maintenance Fee (electricity, cooling, development, and servicing costs), and the technology used for mining. The first two factors are unpredictable and we can not control them, but we always deploy state of the art mining technology, and do our best to keep our data centers running at their maximum capability for you. You can find more in-depth information in our Customer Service Center</p>
    </section>
      </div>
      <section>
      <div className="cost-comparison-container">
      <div className="columnn left-columnn">
        <h2>Costs of a contract with Elite-Mining</h2>
        <ul>
          <li>
          One-time price of purchasing the contract<span className="green-tick">✅</span>
          </li>
          <li>
           No extra fees <span className="green-tick">✅</span>
          </li>
          <li>
           No extra fees <span className="green-tick">✅</span>
          </li>
          <li>
          Best electricity rates possible. <span className="green-tick">✅</span>
          </li>
          <li>Best electricity rates possible.<span className="green-tick">✅</span></li>
          <li>Start Mining immediately<span className="green-tick">✅</span></li>
          <li>We guarantee 100% uptime and cover system downtimes by using our own miners
<span className="green-tick">✅</span></li>
          <li>No Extra Fee<span className="green-tick">✅</span></li>
          <li>No Extra Fee<span className="green-tick">✅</span></li>
          <li>No Extra Fee<span className="green-tick">✅</span></li>
          <li>Hardware lives in a remote location<span className="green-tick">✅</span></li>
          {/* Add more benefits as needed */}
        </ul>
      </div>
      <div className="columnn right-columnn">
        <h2>Costs and externalities of having your own hardware</h2>
        <ul>
          <li>
           Price of Hardware  <span className="red-cross">❌</span>
          </li>
          <li>
         Shipping Costs <span className="red-cross">❌</span>
          </li>
          <li>
           High electricity Rates <span className="red-cross">❌</span>
          </li>
          <li>
          Waiting for delivery - you are losing days,even weeks of mining time <span className="red-cross">❌</span>
          </li>
          <li>
          Loss of mining time due to system downtimes <span className="red-cross">❌</span>
          </li>
          <li>
          Electricity consumption of additional equipment <span className="red-cross">❌</span>
          </li>
          <li>
          Electricity consumption of additional equipment <span className="red-cross">❌</span>
          </li>
          <li>
          Excessive heat <span className="red-cross">❌</span>
          </li>
          <li>
           Loud noises<span className="red-cross">❌</span>
          </li>
         
          {/* Add more drawbacks as needed */}
        </ul>
      </div>
    </div>
      </section>
    </div>
  );
}

export default PricingTable;