import React, { useState } from 'react';
import {db,auth,storage} from "../FirebaseConfig"
import {  ref, set } from "firebase/database";
import { getStorage, ref as Ref2, uploadBytes,getDownloadURL } from 'firebase/storage';
import { Alert } from '@material-ui/lab';
import {
    getAuth,
    createUserWithEmailAndPassword,
    updateProfile,
  } from "firebase/auth";

   const KYCForm=({user})=> {
    const [step, setStep] = useState(1);
    const [identityProof, setIdentityProof] = useState(null);
    const [residencyProof, setResidencyProof] = useState(null);
    const [incomeProof, setIncomeProof] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const handleIdentityProofChange = (e) => {
      if (e.target.files[0]) {
        setIdentityProof(e.target.files[0]);
      }
    };
  
    const handleResidencyProofChange = (e) => {
      if (e.target.files[0]) {
        setResidencyProof(e.target.files[0]);
      }
    };
  
    const handleIncomeProofChange = (e) => {
      if (e.target.files[0]) {
        setIncomeProof(e.target.files[0]);
      }
    };
  
    const handleNext = () => {
      setStep(step + 1);
    };
  
    const handlePrev = () => {
      setStep(step - 1);
    };
  
    const handleSubmit = async () => {
      setLoading(true);
  
      const identityProofRef = Ref2(storage,`${user}/identityProof/${identityProof.name}`);
      const residencyProofRef = Ref2(storage,`${user}/residencyProof/${residencyProof.name}`);
      const incomeProofRef = Ref2(storage,`${user}/incomeProof/${incomeProof.name}`);
  
      await uploadBytes( identityProofRef, identityProof);
      await uploadBytes( residencyProofRef, residencyProof);
      await uploadBytes( incomeProofRef, incomeProof);

      
  
      const identityProofUrl = await getDownloadURL(identityProofRef);
      const residencyProofUrl = await getDownloadURL(residencyProofRef);
      const incomeProofUrl = await getDownloadURL(incomeProofRef);
  
      const data = {
        identityProofUrl,
        residencyProofUrl,
        incomeProofUrl,
      };
  
      
      await set(ref(db, `users/${user}/kycForms`), {
       data
      });
      alert("successful Submission,your request is under review");
      setLoading(false);
      setStep(1);
      setIdentityProof(null);
      setResidencyProof(null);
      setIncomeProof(null);
    };
  
    return (
      <div className="kyc-form">
        {step === 1 && (
          <div className="form-stepK">
            <div className="form-stepK-header">
              <h2>Step 1: Proof of Identity</h2>
            </div>
            <div className="form-stepK-content">
            <p>Please upload an image or PDF of your proof of identity.</p>
             
                <Alert severity="info">Identity proof: This can be in the form of a government-issued identity document such as a passport, driver's license, or national ID card. The document should have a clear photograph, full name, and date of birth.</Alert>
              <input type="file" accept="image/*, application/pdf" onChange={handleIdentityProofChange} />
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="form-stepK">
            <div className="form-stepK-header">
              <h2>Step 2: Proof of Residency</h2>
            </div>
            <div className="form-stepK-content">
            <p>Please upload an image or PDF of your proof of residency.</p>
           
            <Alert severity="info">Address proof: This can be in the form of a recent utility bill, bank statement, or rental agreement that shows the customer's name and address. The document should be issued within the last three months.</Alert>
             
                <input type="file" accept="image/*, application/pdf" onChange={handleResidencyProofChange} />
</div>
</div>
)}
{step === 3 && (
<div className="form-stepK">
<div className="form-stepK-header">
<h2>Step 3: Selfie</h2>
</div>
<div className="form-stepK-content">
<p>Please upload an image facing the camera directly </p>
  <Alert severity='info'>This is solely to ensure you are not a bot,carrying out automated tasks</Alert>  

<input type="file" accept="image/*, application/pdf" onChange={handleIncomeProofChange} />
</div>
</div>
)}
<div className="form-actions">
{step !== 1 && (
<button className="prev" onClick={handlePrev}>
Previous
</button>
)}
{step !== 3 && (
<button className="next" onClick={handleNext}>
Next
</button>
)}
{step === 3 && (
<button className="submit" onClick={handleSubmit} disabled={!identityProof || !residencyProof || !incomeProof || loading}>
{loading ? 'Submitting...' : 'Submit'}
</button>
)}
</div>
</div>
);
}
export default KYCForm;