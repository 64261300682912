import React, { useState, useEffect } from "react";
import axios from "axios";

function WalletAdmin() {
  const [wallets, setWallets] = useState({});
  const [coupon, setcoupon] = useState({});
  const [updatedWallets, setUpdatedWallets] = useState({});
  useEffect(() => {
    axios.get("https://grandstaking-default-rtdb.firebaseio.com/coupon.json")
      .then(response => {
        setcoupon(response.data);
       
      })
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    axios.get("https://grandstaking-default-rtdb.firebaseio.com/wallets.json")
      .then(response => {
        setWallets(response.data);
        setUpdatedWallets(response.data);
      })
      .catch(error => console.log(error));
  }, []);
const handlecouponupdate=()=>{
    axios.patch("https://grandstaking-default-rtdb.firebaseio.com/.json", {coupon})
      .then(response => alert('done'))
      .catch(error => console.log(error));
}
  const handleUpdate = () => {
    axios.patch("https://grandstaking-default-rtdb.firebaseio.com/wallets.json", updatedWallets)
      .then(response => alert('done'))
      .catch(error => console.log(error));
  }

  const handleWalletChange = (event, walletId) => {
    const newWallets = { ...updatedWallets };
    newWallets[walletId].walletAddress = event.target.value;
    setUpdatedWallets(newWallets);
  }

  const walletList = Object.keys(wallets).map(walletId => (
    <div key={walletId}>
      <label htmlFor={walletId}>{wallets[walletId].name}:</label>
      <input type="text" id={walletId} value={updatedWallets[walletId].walletAddress} onChange={(event) => handleWalletChange(event, walletId)} />
    </div>
  ));

  return (
    <div>
      {walletList}
      <button onClick={handleUpdate}>Update Wallets</button>
      <div>Enter Coupon code</div>
      <input type="text" value={coupon} onChange={(e)=>{setcoupon(e.target.value)}}/>
      <button onClick={handlecouponupdate}>Update Coupon</button>

    </div>
  );
}

export default WalletAdmin;
