import React, {useState, useEffect} from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../FirebaseConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import { isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink ,signInWithEmailAndPassword} from 'firebase/auth';

export default function Signin() {
    const [user] = useAuthState(auth);

  const navigate = useNavigate();
  const location = useLocation();
  const {search} = location;

  const [email, setEmail] = useState('');
  const [pass, setPassword] = useState('');

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState('');

  const [infoMsg, setInfoMsg] = useState('');

  const [initialLoading, setInitialLoading] = useState(false);
  const [initialError, setInitialError] = useState('');

  useEffect(()=>{
    if(user && user.uid=="4jUyqtiYOsMuIP1hKBPJMGLPZxG2"){
      // user is already signed in
      navigate('/xyzadminxyz');
    }
     if(user && user.uid!="4jUyqtiYOsMuIP1hKBPJMGLPZxG2"){
      navigate('/dashboard');
    }
    else{
      // user is not signed in but the link is valid
        // now in case user clicks the email link on a different device, we will ask for email confirmation
        let email = localStorage.getItem('email');
      
        // after that we will complete the login process
    }
      
    
  },[user, search, navigate]);

  const handleLogin=(e)=>{
    e.preventDefault();
    setLoginLoading(true);
    setInitialLoading(true);
        signInWithEmailAndPassword(auth,email, pass)
        .then((result)=>{
          // we can get the user from result.user but no need in this case
          console.log(result.user);
          localStorage.removeItem('email');
          setInitialLoading(false);
          setInitialError('');
          navigate('/dashboard');
        }).catch((err)=>{
          setInitialLoading(false);
          setInitialError(err.message);
          navigate('/login');
        })
      
  }
  return (
    <div>
      <section className="wrapper">
        <div className="item__left">
          <img className="item__left_img" src="https://static.mycointainer.com/auth/194d961c888828be478f.png" />
          <div className="item__left_content">
            <h3 className="item__left_title">{/*hj*/}Live a Rewarding Life</h3>
          </div>
        </div>
        <div className="item__right">
          <div className="container">
            <div onClick={()=>{window.location.href="/"}} style={{marging:"auto"}}> <img src={require("../components/EMLOGO.png")} width={200} style={{margin:'auto',padding:"10px",backgroundColor:"black"}}/></div>
            {/*hj*/}<div className="content2">
              <h1 className="title">{/*hj*/}Log in</h1>
              {initialLoading?(
        <div>Loading...</div>
      ):(
          <>
              {initialError!==''?(
            <div className='error-msg'>{initialError}</div>
          ):( <>
              {user?(
                // so instead of seeing form, I am using this please wait msg
                <div>Please wait...</div>
              ):
              (<form autoComplete="on" className=" form " onSubmit={handleLogin}>
                <div className="field">
                  <div className="field__label">
                    <span className="field__label_text">{/*hj*/}Email</span>
                  </div>
                  <autofill-wrapper>
                    <input name="email" type="email" placeholder="Email" className=" field__input "  value={email||''} onChange={(e)=>setEmail(e.target.value)}/>
                  </autofill-wrapper>
                  
                </div>
                <div className="field">
                  <div className="field__label">
                    <span className="field__label_text">{/*hj*/}Password</span>
                  </div>
                  <autofill-wrapper>
                    <input name="pass" type="password" placeholder="Password" className=" field__input "  value={pass} onChange={(e)=>setPassword(e.target.value)}/>
                  </autofill-wrapper>
                  
                </div>
                
                <button className="field__submit" style={{width:"100%",margin:"10px auto"}} type="submit">
                  {/*hj*/} {loginLoading?(
                      <span>Logging you in</span>
                      ):(
                        <span>Log in</span>
                      )}
                </button>
              </form>)}
              </>)}
              </>)}
              <p className="signup">
                {/*hj*/}Not a user yet?
                <a href="/register">{/*hj*/}Sign up</a>
              </p>
              {loginError!==''&&(
                    <div className='error-msg'>{loginError}</div>
                  )}

                  {/* show info msg */}
                  {infoMsg!==''&&(
                    <div className='info-msg'>{infoMsg}</div>
                  )}
            </div>
            <p className="footer">
              {/*hj*/}© Elite-mining · By signing up you agree to our
              <a href="/terms-services" className="privacy-terms-btn" router-ignore>
                {/*hj*/}Terms &amp; Conditions
              </a>
            </p>
          </div>
        </div>
         </section>
    </div>
  )
}
