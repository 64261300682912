import React, { useState, useEffect } from 'react';
import axios from 'axios';


const cryptocurrencies = ['bitcoin', 'litecoin', 'monero', 'zcash'];

function CryptoPrices() {
  const [cryptoData, setCryptoData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/simple/price?ids=${cryptocurrencies.join(
            ','
          )}&vs_currencies=usd`
        );

        setCryptoData(response.data);
        setLoading(false); // Set loading to false after successfully fetching data
      } catch (error) {
        setError(error);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="crypto-container">Loading...</div>;
  }

  if (error) {
    return <div className="crypto-container">Error: {error.message}</div>;
  }

  return (
    <div className="crypto-container">
      {cryptocurrencies.map((crypto) => (
        <div key={crypto} className="crypto-card">
          <h2>{crypto}</h2>
          <img src={require(`../${crypto}.png`)} width={50} alt="" />
          <p>Live Price: ${cryptoData[crypto]?.usd || 'N/A'}</p>

          <button onClick={()=>{window.location.href="/plans"}}>View Mining Plans</button>
        </div>
      ))}
    </div>
  );
}

export default CryptoPrices;
