import React, { useState,useRef } from 'react';

const PinModal = ({ onClose, userCode }) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
const inputref=useRef(null)
  const handlePinChange = (e) => {
     
    setPin(e.target.value);
    setError('');
  };

  const handleSubmit = () => {
   
    if (inputref.current.value == userCode) {
       
      onClose();
    } else {
        alert('Incorrect PIN. Please try again.')
      setError('Incorrect PIN. Please try again.');
      setPin('');
    }
  };

  return (
    <div className="pin-modal-overlay">
      <div className="pin-modal">
        <div className="modal-content">
          <img src={require("../components/EMLOGO2.png")} alt="Logo" className="logo" />
          <h2>Enter 4-Digit PIN</h2>
          <input
            type="password"
            maxLength="4"
           
            className='inpat'
            
            ref={inputref}
          />
          <button className='batin' onClick={handleSubmit}>Submit</button>
          <br />
          {error && <p className="error">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default PinModal;