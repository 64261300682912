import React from 'react';
 // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faCheckCircle,faGamepad,faGlobe, faHeartPulse, faLeaf, faTimeline } from '@fortawesome/free-solid-svg-icons';

const features = [
  {
    icon: faGlobe,
    title: 'DataCenter Pioneer',
    description: 'Our history in crypto mining can trace back to 2013',
  },
  {
    icon: faGamepad,
    title: 'Specialize in DataCenter',
    description: 'Develop standardised and professional DataCenters',
  },
  {
    icon: faHeartPulse,
    title: 'One Stop Support',
    description: 'We provide support for anything that relates to DataCenter',
  },
  {
    icon: faTimeline,
    title: 'Rich Global Experience',
    description: 'Our footprint covers North America, Europe, Central Asia and Southeast Asia',
  },
  {
    icon: faChartBar,
    title: 'Commited to innovation',
    description: 'Actively pursue new ways for better electricity efficiency',
  },
  {
    icon: faLeaf,
    title: 'Go Green',
    description: 'Take consideration of environment and increase the portion of carbon-free energy',
  },
];

const FeatureGrid = () => {
  return (
      <div>
          <h1 style={{maxWidth:"80%",textAlign:'left',margin:"auto"}}> Key Features </h1>
    <div className="feature-grid">
        
      {features.map((feature, index) => (
        <div className="feature-card" key={index}>
          <div className="icon-container">
            <FontAwesomeIcon icon={feature.icon} className="icon2" />
          </div>
          <div className="text-container">
            <h2 className="title">{feature.title}</h2>
            <p className="description2">{feature.description}</p>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default FeatureGrid;