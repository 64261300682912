import React, { useState } from 'react';

function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const navbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'black',
    color: 'white',
    padding: '10px 50px',
    maxWidth: '80%',
    margin: 'auto',
    zIndex:'100'
  };

  const logoStyle = {
    width: '100px',
  };

  const centerPagesStyle = {
    display: 'flex', // Updated to use flex
    gap: '20px',
    margin: '0',
    padding: '0',
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'white',
    listStyleType: 'none',
  };

  const loginButtonsStyle = {
    display: 'flex',
    gap: '10px',
  };

  const buttonStyle = {
    padding: '5px 12px',
    backgroundColor: '#18EAAC',
    color: 'black',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    border: "0.5px solid white",
  };

  const mobileMenuStyle = {
    display: isMobileMenuOpen ? 'flex' : 'none',
    flexDirection: 'column',
    alignItems: 'center',
  
    justifyContent:'center',
    margin:'auto',
    backgroundColor: 'black',
    textAlign:'left'
  
  };

  const mediaQueryStyle = {
    '@media (max-width: 768px)': {
      navbarStyle: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      centerPagesStyle: {
        marginTop: '20px',
      },
      loginButtonsStyle: {
        marginTop: '20px',
      },
    },
  };

  return (
    <div style={{ minWidth: '100vw', backgroundColor: 'black', position: 'relative' }}>
      <nav style={navbarStyle} className='navbar'>
        <div>
          <img style={logoStyle} src={require('./EMLOGO.png')} onClick={()=>{window.location.href="/"}} alt='Logo' />
        </div>
        <div>
          <ul style={centerPagesStyle} className='center-pages'>
            <li style={linkStyle}>
              <a href='/company' style={linkStyle}>
                About
              </a>
            </li>
            <li style={linkStyle}>
              <a href='/plans' style={linkStyle}>
                Pricing
              </a>
            </li>
            <li style={linkStyle}>
              <a href='https://bitcointicker.co/transactions' target="_blank" style={linkStyle}>
                Coin Distribution
              </a>
            </li>
            <li style={linkStyle}>
              <a href='/datacenters' style={linkStyle}>
               Datacenters
              </a>
            </li>
            <li style={linkStyle}>
            <a href='/Institutionalservice' style={linkStyle}>
            Institutional Service
            </a>
          </li>
            <li style={linkStyle}>
              <a href='/support' style={linkStyle}>
                Help & Support
              </a>
            </li>
          </ul>
        </div>
        <div style={mobileMenuStyle} className='mobile-menu'>
        <ul>
          <li style={linkStyle}>
            <a href='/company' style={linkStyle}>
              About
            </a>
          </li>
          <li style={linkStyle}>
            <a href='/plans' style={linkStyle}>
              Pricing
            </a>
          </li>
          <li style={linkStyle}>
            <a href='https://bitcointicker.co/transactions' target="_blank"style={linkStyle}>
              Coin Distribution
            </a>
          </li>
          <li style={linkStyle}>
            <a href='/datacenters' style={linkStyle}>
             Datacenters
            </a>
          </li>
          <li style={linkStyle}>
            <a href='/Institutionalservice' style={linkStyle}>
            Institutional Service
            </a>
          </li>
          <li style={linkStyle}>
            <a href='/support' style={linkStyle}>
              Help & Support
            </a>
          </li>
        </ul>
      </div>
        <div style={loginButtonsStyle} className='login-buttons'>
          <button style={{ color: '#18EAAC', background: 'none', border: 'none' }} onClick={()=>{window.location.href="/login"}}>Login</button>
          <button style={buttonStyle} onClick={()=>{window.location.href="/login"}}>Get Started</button>
        </div>
       
      </nav>
      <div className='hamburger' onClick={toggleMobileMenu}>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
        </div>
    </div>
  );
}

export default Navbar;