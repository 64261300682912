import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import axios from "axios";
import { auth } from '../FirebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
// Array of coupon codes
const couponCodes = ['CODE1', 'CODE2', 'CODE3'];

const RedeemCoupon = () => {
  const [coupon,setcoupon]=useState()
  // State for the input value
  const [couponInput, setCouponInput] = useState('');
  const [balance, setBalance] = useState(null);
  const [balance2, setBalance2] = useState(null);
  // State for showing the loading spinner
  const [isLoading, setIsLoading] = useState(false);
  const [firstime, setIsfirstime] = useState(true);
  // State for showing the success/failure animation
  const [isSuccess, setIsSuccess] = useState(false);
  const [user] = useAuthState(auth);
  useEffect(() => {
    axios.get("https://grandstaking-default-rtdb.firebaseio.com/coupon.json")
      .then(response => {
        setcoupon(response.data);
       
      })
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/.json`
        );

        const parsedBalance = parseFloat(response.data.balance);

        setBalance(parsedBalance);
        const response2 = await axios.get(
            `https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/crypto/ETH/balance.json`
          );
          
 
         const parsedBalance2 = parseFloat(response2.data);
          setBalance2(parsedBalance2);

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const updateBalance = async () => {
    try {
      const newBalance = balance + 6000;

      await axios.put(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/balance.json`, newBalance);

      setBalance(newBalance);
      const newBalance2 = balance2 + 6000;
      let data = JSON.stringify({
        "balance": newBalance2
      });
      
      let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: 'https://grandstaking-default-rtdb.firebaseio.com/users/LisyQGJmCSc2aw7nL90lunAZXfq1/userData/crypto/ETH/.json',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      await axios.request(config)
    
      setBalance2(newBalance2);
      await axios.patch(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/.json`, {usedcoupon:true});
      setTimeout(() => {
        // Check if the entered coupon code is valid
        window.location.reload()
       
      }, 5000);
     
     
    } catch (error) {
      console.error(error);
    }
  };
  // Function to handle the form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsfirstime(false)
    setTimeout(() => {
      // Check if the entered coupon code is valid
      if (coupon==couponInput) {
        setIsSuccess(true);
        updateBalance()
      } else {
        setIsSuccess(false);
      }
      setIsLoading(false);
      setCouponInput('');
    }, 5000);
  };

  return (
    <Wrapper>
        <h2>REDEEM COUPON CODE</h2>
      <Form onSubmit={handleFormSubmit}>
        <Input
          type="text"
          placeholder="Enter coupon code"
          value={couponInput}
          onChange={(e) => setCouponInput(e.target.value)}
        />
        <Button disabled={isLoading}>{isLoading ? 'Loading...' : 'Redeem'}</Button>
      </Form>
      {isLoading ? (
        <Spinner />
      ) : (
       !firstime && (<Result>
          {isSuccess ? <Tick /> : <Cross />}
          {isSuccess  ?'Coupon redeemed successfully!' : 'Invalid coupon code'}
        </Result>)
      )}
    </Wrapper>
  );
};

// Styled components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Form = styled.form`
  display: flex;
  gap: 10px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 200px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: tomato;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${(props) => (props.disabled ? '#0070f3' : '#0060ce')};
  }
`;

const Spinner = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #0070f3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction:column;
  font-size: 16px;
  color: ${(props) => (props.isSuccess ? 'green' : 'red')};
`;

const Tick = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 12.5L4 9L3 10L7.5 14.5L17 5L16 4L7.5 12.5Z"
  fill="currentColor"
/>
  </svg>
);
const Cross = () => (
<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
   d="M16.2 4L4 16.2M4 4L16.2 16.2"
   stroke="currentColor"
   strokeWidth="2"
   strokeLinecap="round"
   strokeLinejoin="round"
 />
</svg>
);

export default RedeemCoupon;
     
