import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import { Table, TableBody,Button, TableCell, TableContainer, TableHead, TableRow, Paper,TablePagination,makeStyles } from "@material-ui/core";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../FirebaseConfig';
import { green, red } from "@material-ui/core/colors";
import moment from "moment";
import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(3),
      margin: "10px",
      maxWidth: "100%",
      backgroundColor:"rgba(17, 25, 40, 0.75)"
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: theme.spacing(2),
    },
    input: {
    
     margin:theme.spacing(2),
     color:'black !important',
     padding:"3px"
    },
    button: {
      margin: theme.spacing(2),
    },
    table: {
        maxWidth: "90%",
        margin:'auto',
        '@media (max-width: 570px)': {
          maxWidth: '100%',
        },
      },
      success: {
        color: green[500],
      },
      pending: {
        color: red[500],
      },
  }));
const TransactionsTable = ({symbol,coinName}) => {
    const classes=useStyles()
  const [transactions, setTransactions] = useState([]);
  const[balance,setbalance]=useState(0)
  const[amount,setamount]=useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [user] = useAuthState(auth);
  const walletRef= useRef(null)
  const coins=[
    {
      "coinname": "Ethereum",
      "logo": "https://cryptologos.cc/logos/ethereum-eth-logo.png",
      "symbol": "ETH",
      "description": "A decentralized platform for building smart contracts and decentralized applications (dApps)."
    },
    {
      "coinname": "Bitcoin",
      "logo": "https://cryptologos.cc/logos/bitcoin-btc-logo.png?v=026",
      "symbol": "BTC",
      "description": "A decentralized platform for building smart contracts and decentralized applications (dApps)."
    },
    {
      "coinname": "Zcash",
      "logo": "https://cryptologos.cc/logos/zcash-zec-logo.png?v=026",
      "symbol": "ZCH",
      "description": "A proof-of-stake blockchain platform designed to enable secure and reliable transactions."
    },
    {
      "coinname": "Monero",
      "logo": "https://cryptologos.cc/logos/monero-xmr-logo.png?v=026",
      "symbol": "XMR",
      "description": "The native token of the Binance exchange, used for trading fees, discounts, and other features."
    },
    {
      "coinname": "Litecoin",
      "logo": "https://cryptologos.cc/logos/litecoin-ltc-logo.png?v=026",
      "symbol": "LTC",
      "description": "A multichain network that enables cross-chain interoperability and communication between different blockchains."
    },
    {
      "coinname": "SHIBA",
      "logo": "https://cryptologos.cc/logos/shiba-inu-shib-logo.png?v=026",
      "symbol": "SHIB",
      "description": "A decentralized oracle network that provides real-world data to smart contracts on the blockchain."
    },
    {
      "coinname": "Cardano",
      "logo": "https://cryptologos.cc/logos/cardano-ada-logo.png",
      "symbol": "ADA",
      "description": "A proof-of-stake blockchain platform designed to enable secure and reliable transactions."
    },
    {
      "coinname": "Binance Coin",
      "logo": "https://cryptologos.cc/logos/binance-coin-bnb-logo.png",
      "symbol": "BNB",
      "description": "The native token of the Binance exchange, used for trading fees, discounts, and other features."
    },
    {
      "coinname": "Polkadot",
      "logo": "https://cryptologos.cc/logos/polkadot-new-dot-logo.png",
      "symbol": "DOT",
      "description": "A multichain network that enables cross-chain interoperability and communication between different blockchains."
    },
    {
      "coinname": "Chainlink",
      "logo": "https://cryptologos.cc/logos/chainlink-link-logo.png",
      "symbol": "LINK",
      "description": "A decentralized oracle network that provides real-world data to smart contracts on the blockchain."
    },
    {
      "coinname": "Stellar",
      "logo": "https://cryptologos.cc/logos/stellar-xlm-logo.png",
      "symbol": "XLM",
      "description": "A decentralized payment network that facilitates cross-border transactions and asset transfers."
    },
    {
      "coinname": "VeChain",
      "logo": "https://cryptologos.cc/logos/vechain-vet-logo.png",
      "symbol": "VET",
      "description": "A blockchain platform that focuses on supply chain management and asset tracking."
    },
    {
      "coinname": "Theta",
      "logo": "https://cryptologos.cc/logos/theta-token-theta-logo.png",
      "symbol": "THETA",
      "description": "A decentralized video delivery network that aims to improve the video streaming experience for users."
    },
    {
      "coinname": "Solana",
      "logo": "https://cryptologos.cc/logos/solana-sol-logo.png",
      "symbol": "SOL",
      "description": "A high-performance blockchain platform that enables fast and cheap transactions with low fees."
    },
    {
      "coinname": "Avalanche",
      "logo": "https://cryptologos.cc/logos/avalanche-avax-logo.png",
      "symbol": "AVAX",
      "description": "A blockchain platform that enables decentralized applications and enterprise blockchain solutions."
    },
    {
      "coinname": "Tezos",
      "logo": "https://cryptologos.cc/logos/tezos-xtz-logo.png",
      "symbol": "XTZ",
      "description":"An open-source blockchain platform that allows for self-amendment and on-chain governance through a proof-of-stake consensus mechanism."
  },
  {
  "coinname": "Cosmos",
  "logo": "https://cryptologos.cc/logos/cosmos-atom-logo.png",
  "symbol": "ATOM",
  "description": "A decentralized network of independent blockchains that can communicate with each other through the Cosmos Hub."
  },
  {
  "coinname": "Algorand",
  "logo": "https://cryptologos.cc/logos/algorand-algo-logo.png",
  "symbol": "ALGO",
  "description": "A blockchain platform that aims to provide secure and efficient transactions with fast confirmation times."
  },
  {
  "coinname": "NEO",
  "logo": "https://cryptologos.cc/logos/neo-neo-logo.png",
  "symbol": "NEO",
  "description": "A blockchain platform that enables the development of decentralized applications and smart contracts."
  },
  {
  "coinname": "Harmony",
  "logo": "https://cryptologos.cc/logos/harmony-one-logo.png",
  "symbol": "ONE",
  "description": "A fast and secure blockchain platform that aims to enable the creation of decentralized applications and marketplaces."
  },
  {
  "coinname": "Zilliqa",
  "logo": "https://cryptologos.cc/logos/zilliqa-zil-logo.png",
  "symbol": "ZIL",
  "description": "A blockchain platform that uses sharding to enable high throughput and low transaction fees for decentralized applications."
  },
  {
  "coinname": "ICON",
  "logo": "https://cryptologos.cc/logos/icon-icx-logo.png",
  "symbol": "ICX",
  "description": "A blockchain platform that aims to connect various blockchain networks and enable interoperability between them."
  },
  {
  "coinname": "Ontology",
  "logo": "https://cryptologos.cc/logos/ontology-ont-logo.png",
  "symbol": "ONT",
  "description": "A blockchain platform that focuses on identity verification and data exchange through smart contracts."
  },
  {
  "coinname": "Matic Network",
  "logo": "https://cryptologos.cc/logos/matic-network-matic-logo.png",
  "symbol": "MATIC",
  "description": "A layer 2 scaling solution that aims to improve the performance and scalability of Ethereum-based decentralized applications."
  },
  {
  "coinname": "Elrond",
  "logo": "https://cryptologos.cc/logos/elrond-egld-logo.png",
  "symbol": "EGLD",
  "description": "A blockchain platform that uses sharding and adaptive state sharding to enable fast and secure transactions with low fees."
  },
  {
  "coinname": "Kusama",
  "logo": "https://cryptologos.cc/logos/kusama-ksm-logo.png",
  "symbol": "KSM",
  "description": "A blockchain platform that enables innovation and experimentation by allowing developers to build and deploy custom blockchain solutions."
  },

  {
  "coinname": "Polygon",
  "logo": "https://cryptologos.cc/logos/polygon-matic-logo.png",
  "symbol": "MATIC",
  "description": "A layer 2 scaling solution for Ethereum that aims to provide faster and cheaper transactions for decentralized applications."
  },
  {
    "coinname": "Ethereum Classic",
    "logo": "  https://cryptologos.cc/logos/ethereum-classic-etc-logo.png?v=026",
    "symbol": "ETC",
    "description": "A layer 2 scaling solution for Ethereum that aims to provide faster and cheaper transactions for decentralized applications."
    }
]
  const [coin,setcoin]=useState(coinName)
  const[wallet,setwallet]=useState()
  const[sym,setsym]=useState(symbol)
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    console.log(amount)
    setamount(event.target.value)
    let inputValue = event.target.value;
    // Remove all non-digits from the input value
    inputValue = inputValue.replace(/[^\d]/g, '');
    // Format the input value as a currency string
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(inputValue / 100);
    setValue(formattedValue);
    
  };
  const SelectRef=useRef(null)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/withdrawals.json`);
        const data = response.data;
        setTransactions(data ? Object.values(data) : []);
        const response2 = await axios.get(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/balance.json`);
        const data2 = response2.data;
        
        setbalance(data2);
        const response3 = await axios.get(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/wallets/${sym}.json`);
        const data3 = response3.data;
setwallet(data3?data3.address:'')
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [sym]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, transactions.length - (page - 1) * rowsPerPage);

  return (
    <>
    <div style={{margin:"10px auto"}}>
      <select  value={sym} onChange={(e)=>{
       
        setsym(e.target.value)
      }}>
        <option value={""}>Wallets</option>
        {coins.map((coin)=>(
<option value={coin.symbol}>{coin.coinname}</option>
        ))

        }
      </select>
    </div>
    <div style={{margin:'auto'}}><h2 style={{textAlign:'center'}}>{sym}-Withdrawal</h2> 
    
   {!!wallet? <div><p>wallet Address:{wallet}</p>
   <div style={{display:"flex",flexDirection:'column',gap:"10px"}}>
     <Alert severity="info">Make sure you have the Correct withdrawal address for your assets.</Alert>
     <input type="text" value={wallet} placeholder="Enter wallet address" />
     <button onClick={()=>{
       if(!walletRef.current.value){
         return;
       }
       axios.patch(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/wallets/${sym}/.json`,{"address":walletRef.current.value}).then(()=>{
         alert("wallet Added Successfully");
       }).catch((err)=>{
         alert("Something Went Wrong !!! try again.")
         console.log("err",err);
       })
     }}style={{border:'none',backgroundColor:'tomato',color:'white',padding:"3px"}}>Update Wallet</button>
   </div></div>:<div><p>you currently do not have a wallet for this Asset</p>
   <div style={{display:"flex",flexDirection:'column',gap:'10px'}}>
     <input type="text" ref={walletRef}
     placeholder="Enter wallet address" />
     <button onClick={()=>{
       if(!walletRef.current.value){
         return;
       }
       axios.put(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/wallets/${sym}/.json`,{"address":walletRef.current.value}).then(()=>{
         alert("wallet Added Successfully");
       }).catch((err)=>{
         alert("Something Went Wrong !!! try again.")
         console.log("err",err);
       })
     }} style={{border:'none',backgroundColor:'tomato',color:'white',padding:"3px"}}>Add Wallet</button>
   </div>
   </div>}
    </div>
     <TableContainer component={Paper} elevation={0} className={classes.table}>
      <Table aria-label="transactions table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">Withdrawal Amount</TableCell>
            <TableCell align="right">Asset</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Comment</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions
            .slice().reverse()
            .map((transaction,ind) => (
              <TableRow key={ind}>
                <TableCell> {moment(transaction.date).format("YYYY-MM-DD")}</TableCell>
                <TableCell align="right">{transaction.amount}</TableCell>
                <TableCell align="right">{transaction.symbol}</TableCell>
                <TableCell align="right">
                  <span style={{ color: transaction.status === "successful" ? "green" : "red" }}>{transaction.status}</span>
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: transaction.status === "successful" ? "green" : "red" }}>{transaction.comment||"N/A"}</span>
                </TableCell>
              </TableRow>
            ))}
          
           {transactions.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>No withdrawals available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
     
      <div>
      <label style={{color:"black",width:"70%"}} htmlFor="amount-input">Amount (minimum 300)</label>
            <input
              
              id="amount-input"
              value={value}
              style={{width:'70%'}}
              onChange={handleChange}
             
            />
        <button variant="contained" color="primary" style={{margin:'10px auto',backgroundColor:'teal',border:'none'}}   className="field__submit" onClick={()=>{
         const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        const doubleValue = parseFloat(value.replace(/[^\d.-]/g, ''));
         if(doubleValue>balance || balance==0){
            alert("insufficient funds!!")
            return
          }
axios.post(`https://grandstaking-default-rtdb.firebaseio.com/users/${user.uid}/userData/withdrawals.json`,{status:'pending',date:moment().format("YYYY-MM-DD"),amount:value,symbol:sym}).then(()=>{
  alert("Withdrawal request submitted successfully")
 
}).catch(()=>{
  alert("Something went wrong please try again.")
  
})
        }}> REQUEST WITHDRAWAL </button></div>
    </TableContainer></>
   
  );
};

export default TransactionsTable;
