import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle ,faFileCircleCheck,faFunnelDollar, faGasPump,faHourglass1,faRankingStar,faListCheck} from '@fortawesome/free-solid-svg-icons';

const services = [
  {
    icon: faFunnelDollar,
    header: 'Start with as low as 200 USD',
    description: 'Minimum order price is 200 USD for the cheapest algorithm.',
  },
  {
    icon: faListCheck,
    header: 'No contract ,No risk',
    description: 'Cancel at any time and get your remaining funds back with no cancellation fee.',
  },
  {
    icon: faGasPump,
    header: 'Fast delivery',
    description: 'Cancel at any time and get your remaining funds back with no cancellation fee.',
  },
  {
    icon: faRankingStar,
    header: 'Mine on your favorite pool',
    description: 'You decide how and when you want to buy hashing power and which pool to direct it to.',
  },
  {
    icon: faHourglass1,
    header: 'Real-time statistics',
    description: 'Follow your workers and their performance.',
  },
  {
    icon: faCheckCircle,
    header: 'Valid shares only',
    description: 'Never pay for dead, faulty configured rigs or invalid shares',
  },
];

const ServiceGrid = () => {
  return (
    <div className="service-grid">
      {services.map((service, index) => (
        <div className="service-card" key={index}>
          <FontAwesomeIcon icon={service.icon} className="icon"  />
          <h2 className="sheader">{service.header}</h2>
          <p className="description">{service.description}</p>
        </div>
      ))}
    </div>
  );
};

export default ServiceGrid;
