import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, Link } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';

import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = makeStyles((theme) => ({
  root: {
    background:'rgba(223,243,252,0.5466780462184874)',
     color: 'black',
   width:'100vw',
   minWidth:'100vw',
  },copyright: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  container:{
      display:"flex",
      flexDirection:'colum',
      justifyContent:'center'
  },
  black:{
    backgroundColor: 'rgba(223,243,252,0.5466780462184874)',
    color: 'white',
   
  },
  social: {
    marginBottom: theme.spacing(4),
    '& > *': {
      margin: theme.spacing(0, 4),
      fontSize: '2rem',
      '&:hover': {
        color: "black",
      },
    },
  },
  heading: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    
  },
  links: {
    '& > *': {
      display: 'block',
      marginBottom: theme.spacing(1),
      color: 'black',
     
      
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
  images: {
    marginTop: theme.spacing(2),
    display:"flex",
    justifyContent:"space-evenly",
    '& > *': {
     
      marginRight: theme.spacing(1),
    },
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <div >
      <Container className={classes.root} >
         
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" className={classes.social}>
              {//<Link href="https://instagram.com/elitemining_?utm_source=qr&igshid=NGExMmI2YTkyZg=="><InstagramIcon style={{width:"50px"}} /></Link>
              }
              <Link href="https://discord.com/"><img width='50' src="https://i.postimg.cc/s2SH3Gsb/image.png" alt="" /></Link>
            { // <Link href="https://twitter.com/elitemining_?s=11&t=fSLNMbBNz5sMszTWZM_HuQ"><TwitterIcon style={{width:"50px"}} /></Link>
            }
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" className={classes.heading}>Company</Typography>
            <div className={classes.links}>
              <Link href="/company">About Us</Link>
              <Link href="/register">Assets</Link>
             
              
              </div>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" className={classes.heading}>Support</Typography>
            <div className={classes.links}>
              <Link href="/support">Support Center</Link>
              
              <Link href="/support">Send Feedback</Link>
            </div>
          </Grid>
       
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" className={classes.heading}>Resources</Typography>
            <div className={classes.links}>
              <Link href="/Terms-services">Terms of Service</Link>
              <Link href="/Privacypolicy">Privacy Policy</Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.black}>
            <div className={classes.images}>
            <div style={{backgroundColor:'#071f4b',padding:'10px',borderRadius:'10px'}}><img src={require('../components/EMLOGO.png')} alt="Logo" height="60" /></div>
            <div className="sc-bYMpWt jiFSkU">
      <a
        
      >
        <img alt="" src="https://www.mycointainer.com/landing-main/media/app-store.10182d30.svg" />
      </a>
      <a
       
      >
        <img alt="" src="https://www.mycointainer.com/landing-main/media/play-market.f5ed18aa.svg" />
      </a>
    
    </div> </div>

          </Grid>
         
          <Grid item xs={12} sm={12} className={classes.copyright}>
            <p style={{fontWeight:"bold" ,fontSize:'10px',color:"white",backgroundColor:'black'}}> All Right reserved @elite-mining.com 2020</p>
          </Grid>
        </Grid>
        
      </Container>
    </div>
  );
}

export default Footer;
