import logo from './logo.svg';

import './App.css';
import Home from './pages/Datacenters';
import Contact from './pages/contact';
import Log from "./pages/signin"
import Register from "./pages/register"
import Dash from "./pages/Dashbord2"
import Admin from "./pages/dashboard"
import Pricing from "./pages/pricing"
import MainAdmin from "./pages/Admin"
import Landing from "./pages/landing"
import Terms from './pages/Terms';
import Staking from "./pages/staking"
import React, { useState,useEffect } from "react";
import Modal from 'react-modal';
import { FaStar } from 'react-icons/fa';
import About from "./pages/About"
import Privacy from './pages/Privacy';
import Ins from "./pages/Institution"
import ToastComponent from './components/toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  'rodal/lib/rodal.css';
import {
  BrowserRouter as Router,
  Switch,
  Routes,Route,
  Link
} from "react-router-dom";
function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  
  
  const handleButtonClick = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const reviews = [  {    "user": {      "name": "Mathew waser",      "image": "https://randomuser.me/api/portraits/men/1.jpg"    },    "company": "Acme Inc.",
      "review": "I have been with Elite Mining for 2+ years and very seldom do they have any issues with their platform! The few times there was an issue it was fixed quickly and they are great with their communicating to you what the issue is & how they are solving it! Kudos to Elite Mining and their team of professionals!",    "stars": 4,    "language": "English",    "country": "United States"  }, 

       {    "user": 
       {      "name": "Maria Rodriguez",      "image": "https://randomuser.me/api/portraits/women/2.jpg"    },
           "company": "XYZ Corporation",
               "review": "Great service! very polite and helpful. I made an error while transferring crypto from binance, and they helped me solve it, when perhaps they didnt even have to since it was my error. it did take some time but they were very responsive along the way with updates etc. looking forward to using the service. hope its as great as their customer care",    "stars": 5,    "language": "Spanish",    "country": "Spain"  },
                 {    "user": {      "name": "Satoshi Tanaka",      "image": "https://randomuser.me/api/portraits/men/3.jpg"    },    "company": "ABC Co. Ltd.", 
                    "review": "I want to thank the Elite Mining support staff, more specifically, Vasyl! I have never in my life had a better or more professional resolution to a problem. My issue was dealt with quickly and professionally by the support staff. As many of you know, this isn't always the case. Five stars was an absolute given for me!Thank you Vasyl and everyone else that made my bad situation great!",    "stars": 5,    "language": "Japanese",    "country": "Japan"  },  {    "user": {      "name": "Jasmine Lee",      "image": "https://randomuser.me/api/portraits/women/4.jpg"    },    "company": "DEF Enterprises",    "review": "TI am always skeptical cause of what is going on in crypto. This company has put me at ease with their great rates and customer service! I was amazed at how fast customer support responded when I had an issue. I’ll continue to invest with this great company!",    "stars": 4,    "language": "English",    "country": "South Korea"  },  {    "user": {      "name": "Fatima Ali",      "image": "https://randomuser.me/api/portraits/women/5.jpg"    },    "company": "GHI Industries",    "review": "I had to Mine my cryptos here. There were some mix ups with deposits, so you have to check if everything is running correctly. In my opionion they have high fees which you can pay to get rid of. The customer service responded quick and was helpful, so there is that.The design is very good and everything is easy to do.",    "stars": 5,    "language": "French",    "country": "Morocco"  }]

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex:"99999999",

      maxHeight:"500px",
      maxWidth:'60%'
    
    },
  };
  return (
    <div className="App">
     
       <button className="Button" onClick={handleButtonClick}>
        Reviews <FaStar className="StarIcon" />
      </button>
      <ToastContainer position="bottom-left" autoClose={2000} /> {/* Configure position and other options */}
      <ToastComponent />
     
      <Modal isOpen={modalIsOpen}  style={customStyles} onRequestClose={handleModalClose}>
       <div style={{backgroundColor:'tomato',padding:'10px',width:'100%'}}><h3 style={{textAlign:'center',color:'white'}}>Top Elite Mining Reviews</h3></div> 
        {reviews.map((review, index) => (
          <div className="ReviewCard" key={index}>
          
            <div className="ReviewContent">
              <h3>{review.user.name}</h3>
              <p>{review.country}</p>
              <p>{review.review}</p>
              <div className="StarRating">
                {[...Array(review.stars)].map((_, index) => (
                  <FaStar key={index} style={{color:'gold'}} />
                ))}
              </div>
            </div>
          </div>
        ))}
      </Modal>
         <Router>
      <div>
       
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
       
        <Routes>
          <Route exact path="/" element={<Landing/>}/>
          <Route exact path="/datacenters" element={<Home/>}/>
          <Route path="/login" element={<Log/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/dashboard" element={<Dash/>}/>
          <Route path="/InstitutionalService" element={<Ins/>}/>
          <Route path="/Company" element={<About/>}/>
          <Route path="/Support" element={<Contact/>}/>
          <Route path="/Plans" element={<Pricing/>}/>
          <Route path="/adminuser" element={<Admin/>}/>
          <Route path="/xyzAdminxyz" element={<MainAdmin/>}/>
          <Route path="/Terms-services" element={<Terms/>}/>
          <Route path="/PrivacyPolicy" element={<Privacy/>}/>
        </Routes>
      </div>
    </Router>
     
    </div>
  );
}

export default App;
