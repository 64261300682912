import React from 'react'
import Navbar from "../components/Navbar"
import ToastComponent from '../components/toast'
import CenteredSection from '../components/Datacenters';
import Cryptoprices from '../components/cryptoprices'
import Footer from '../components/footer';
import Navigation from '../components/MobileNav';
import PricingTable from '../components/pricingplan';
import ServiceGrid from '../components/servicegrid';
function Pricing() {
    const buttonStyle = {
        padding: '10px 15px',
        backgroundColor: '#01B580',
        color: 'white',
        border: '1px solid white',
        cursor: 'pointer',
        borderRadius:'5px'
      };
     
  return (
    <div>
      <Navbar/>
  
      <section className="">
      <div className="planheader"></div>
      <div className="cont">
        <h1 className="header">Start Mining today!</h1>
        <p className="text">Your state-of-the-art mining hardware is already running! Just choose your favorite cryptocurrency or mining algorithm and get started in minutes!</p>
        <button style={buttonStyle} onClick={()=>{window.location.href="/register"}}>Get started today</button>
      </div>
    </section>
    <div className="custom-container" style={{backgroundColor:'black',borderRadius:'10px'}}>
     
      <div className="custom-right-section">
      <h1 style={{color:'white'}}>Hash Rate Plans</h1>
    
        <p style={{color:'white'}}>
        Enjoy peace of mind mining experience with Cloud Hash Rate Plans. Select your hash rate size, durations, mining pool, and start to accumulate your favorite cryptos. Pay electricity fees on the go. Start now!
        </p>
      </div>
    </div>
<ServiceGrid/>
    <PricingTable/>
    <div className="sc-cwSeag emhsJJ">
  <img
    alt=""
    src="https://www.mycointainer.com/landing-main/media/footer-texture.d161594c.svg"
    className="sc-lllmON fgViNB"
    style={{ height: 593, width: "100%" }}
  />
  <div className="sc-iJnaPW gvcNWt">
    <div className="sc-gikAfH foHjIi">
      Get started today with as little as $200 and Mine {" "}
      cryptocurrencies
    </div>
    <div className="sc-ezOQGI deCcyC">Download our app on Android or iOS(Coming this December)</div>
    <div className="sc-bYMpWt jiFSkU">
      <a
        
      >
        <img alt="" src="https://www.mycointainer.com/landing-main/media/app-store.10182d30.svg" />
      </a>
      <a
       
      >
        <img alt="" src="https://www.mycointainer.com/landing-main/media/play-market.f5ed18aa.svg" />
      </a>
    
    </div>
  </div>
  <img
    loading="lazy"
    alt=""
    src="https://i.postimg.cc/LsW6qkjg/image-1.jpg"
    className="sc-kMjNwy ejGqCd"
  />
</div>
  <br/><br/><br/><br/><br/>
  <Footer/>
    </div>
  )
}

export default Pricing